<template>
  <div class="iq-card-body p-0">
    <div class="row" v-if="channel.length > 0">
      <div
        class="col-md-6 col-lg-6 mb-3"
        v-for="(item, index) in channel"
        :key="index"
      >
        <div class="iq-friendlist-block">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <div class="imageBox" @click="refresh(item.channel_id, item.id)">
                <el-image
                  :src="item.image"
                  alt="profile-img"
                  class="avatar-130"
                  style="line-height: 65px; width: 100%; height: 100%"
                >
                  <div slot="error" class="image-slot">
                    <img style="width:150px;height:150px" src="@/assets/images/public/squ_jci.jpg" alt="">
                  </div>
                </el-image>
              </div>
              <div class="friend-info ml-3">
                <h5
                  style="
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                  :title="item.title"
                >
                  {{ item.title }}
                </h5>
                <p>
                  <span>{{ item.type_desc }}</span>
                </p>
              </div>
            </div>
            <div class="iq-card-header-toolbar d-flex align-items-center">
              <span
                v-if="isHideIcon"
                @click="confirmDel(item, index)"
                class="dropdown-toggle btn btn-secondary mr-2"
                id="dropdownMenuButton01"
                data-toggle="dropdown"
                aria-expanded="true"
                role="button"
              >
                <i class="ri-check-line mr-1 text-white font-size-16"></i>
                {{ $t("MyJCI.c2c@followed") }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else style="background:#fff;" class="d-flex justify-content-center mt-2">
      <img style="width:250px;" src="@/assets/images/group/empty1.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    channel: {
      type: Array,
      default: [],
    },
    isHideIcon: {
      type: Boolean,
      default: "",
    },
  },
  data() {
    return {};
  },
  created() {},

  methods: {
    refresh(channel_id, id) {
      this.$router.push(`/organization-detail?channel_id=${channel_id}&id=${id}`);
    },
    // 取消关注时询问
    confirmDel(channel, index) {
      this.$confirm("Do you want to cancel the following?", "Tips", {
        distinguishCancelAndClose: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.channelFollow(channel, index);
        })
        .catch(() => {
          
        });
    },
    // 取消关注
    channelFollow(channel, index) {
      this.$http
        .channelFollow({ channel_id: channel.channel_id, organization_id: channel.id, type: 2 })
        .then((res) => {
          if (res.status == 200) {
            this.channel.splice(index, 1);
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 968px) {
  .imageBox {
    width: 150px !important;
    height: 150px !important;
    img {
      width: 150px;
      height: 150px;
    }
  }
  
  .friend-info{
    h5{
      width: 140px;
      margin-bottom: 0.5rem;
    }
    p{
      width: 140px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; //行数
      display: -webkit-box;
    }
  }
}
@media (max-width: 968px) {
  .imageBox {
    width: 100px !important;
    height: 100px !important;
    img {
      width: 100px;
      height: 100px;
    }
  }
  .friend-info{
    padding-left: 5px;
    h5{
      width: 110px;
      margin-bottom: 5px;
    }
    p{
      width: 110px;
      line-height: 1.2;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; //行数
      display: -webkit-box;
    }
  }
  .btn {
    font-size: 10px !important;
  }
}
</style>
