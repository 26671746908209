<template>
  <section class="HashtagsAboutYou">
    <h4>Hashtag</h4>
    <hr />
    <div class="row">
      <span class="mb-0 mr-1 yellow-text-bg" v-for="(v, i) of list" :key="i">{{
        v.tag_name
      }}</span>
    </div>
  </section>
</template>
<script>
export default {
  components: {},
  props:{
    list:{
      type: Array,
      default: []
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  created () {},
  methods: {},

}
</script>
<style src='' lang='scss' scoped>
.DetailsAboutYou {
  .yellow-text-bg {
    padding: 12px 8px;
    background-color: #fffcf3;
    color: #ffba68;
  }
}
</style>
