<template>
  <div>
    <iq-card>
      <template v-slot:body>
        <tab-nav
          :pill="true"
          class="d-flex align-items-center p-0 m-0"
          :tabs="true"
        >
          <tab-nav-items
            :active="true"
            id="pills-photo-you-tab"
            href="#photo"
            ariaControls="pills-photo-you"
            role="tab"
            :ariaSelected="true"
            :title="$t('MyJCI.71e@photo')"
          />
          <tab-nav-items
            :active="false"
            id="pills-your-photo-tab"
            href="#video"
            @click.native="checkoutVideo"
            ariaControls="pills-your-photo"
            role="tab"
            :ariaSelected="false"
            :title="$t('MyJCI.e26@video')"
          />
        </tab-nav>

        <!-- 相册图片 -->
        <div class="tab-content" v-if="!is_album_detail">
          <tab-content-item
            :active="videoDetail != 1 ? true : false"
            id="photo"
            aria-labelled-by="pills-photo-you-tab"
          >
            <div
              class="d-flex justify-content-between align-items-center pt-3 pb-3"
            >
              <h5>{{ $t("MyJCI.852@albums") }}</h5>
              <b-button
                v-if="isHideIcon"
                @click="
                  $bvModal.show('album');
                  addUpdateFlag = 0;
                "
                variant="none"
                class="iq-bg-primary"
                >+ {{ $t("MyJCI.08e@add_album") }}</b-button
              >
            </div>

            <Albums
              :albumsList="albumList"
              :deleteAlbumFunc="deleteAlbum"
              @editOpenModal="queryEditAlbum"
              @toAlbumDet="albumDet"
              :isHideIcon="isHideIcon"
            >
            </Albums>
            <el-pagination
              layout="prev, pager, next"
              :page-size="per_page"
              :total="total"
              style="text-align: right; margin: 20px 0"
              @current-change="changePage"
              :current-page="current_page"
              hide-on-single-page
            >
            </el-pagination>
            <div
              class="d-flex justify-content-between align-items-center pt-3 pb-3"
            >
              <h5>All photos</h5>
              <el-upload
                class="avatar-uploader"
                action="no"
                :http-request="photoUpload"
                :show-file-list="false"
              >
                <b-button variant="none" class="iq-bg-primary" v-if="isHideIcon"
                  >+ Add Photo</b-button
                >
              </el-upload>
            </div>
            <Photo
              :photoListData="photoList"
              :setCoverFunc="setCover"
              :deletePhotoFunc="deletePhoto"
              :showMoveModalFunc="showMoveModal"
              :isHideIcon="isHideIcon"
              :isShowNum="isShowNum"
            />
            <el-pagination
              layout="prev, pager, next"
              :page-size="photo_page"
              :total="photoTotal"
              style="text-align: right; margin: 20px 0"
              @current-change="changePhoto"
              :current-page="current_photo"
              hide-on-single-page
            >
            </el-pagination>
          </tab-content-item>
          <tab-content-item
            :active="videoDetail == 1 ? true : false"
            id="video"
            aria-labelled-by="pills-your-photo-tab"
            class="p-2"
          >
            <div
              class="d-flex justify-content-between align-items-center pt-3 pb-3"
            >
              <h5>All Video</h5>
              <el-upload
                action="no"
                :show-file-list="false"
                :http-request="addVideo"
              >
                <b-button v-if="isHideIcon" variant="none" class="iq-bg-primary"
                  >+ Add Video</b-button
                >
              </el-upload>
            </div>
            <Video
              :deleteVideoFunc="delVideos"
              :isHideIcon="isHideIcon"
              :videoList="videoesList"
            />
            <div
              class="bottomof"
              v-if="totalVid > 12"
              style="text-align: right; margin-top: 30px; margin-bottom: 10px"
            >
              <el-pagination
                background
                layout="prev, pager, next"
                :page-size="12"
                @current-change="current_change"
                :current-page.sync="currentVid"
                :total="totalVid"
              >
              </el-pagination>
            </div>
          </tab-content-item>
        </div>

        <!-- 相册详情 -->
        <div
          v-else
          class="album_detail"
          v-loading="loading"
          element-loading-text="uploading"
        >
          <div class="d-flex justify-content-between">
            <ul class="album_path p-0 mt-3">
              <li
                style="cursor: pointer"
                @click="(is_album_detail = false), (videoDetail = 0)"
              >
                {{ $t("MyJCI.03f@album") }}
              </li>
              <li>></li>
              <li>{{ $t("MyJCI.e4d@landscape") }}</li>
            </ul>
            <el-upload
              class="avatar-uploader"
              action="no"
              :http-request="imgUpload"
              :show-file-list="false"
            >
              <b-button variant="none" class="iq-bg-primary" v-if="isHideIcon"
                >+ Add Photo</b-button
              >
            </el-upload>
          </div>

          <DetPhoto
            :photoListData="albumDetailPhoto"
            :setCoverFunc="setCover"
            :deletePhotoFunc="deletePhoto"
            :showMoveModalFunc="showMoveModal"
            :isHideIcon="isHideIcon"
            :isShowNum="isShowNum"
          />
        </div>
      </template>
    </iq-card>

    <!-- add Alum -->
    <b-modal
      id="album"
      :title="$t('MyJCI.08e@add_album')"
      hide-footer
      class="modal-self"
    >
      <b-form-input
        v-model="addAlbumData.title"
        :placeholder="$t('MyJCI.6a3@title')"
        class="mb-3"
      ></b-form-input>
      <b-form-input
        v-model="addAlbumData.sort"
        :placeholder="$t('MyJCI.da6@sort')"
        class="mb-3"
      ></b-form-input>
      <!-- <b-form-input
        v-model="addAlbumData.introduce"
        placeholder="Introduce"
        class="mb-3"
      ></b-form-input> -->

      <button @click="confirmModal" class="btn btn-primary d-block w-100 mt-3">
        {{ $t("MyJCI.f2c@submit") }}
      </button>
    </b-modal>

    <!-- moveTo -->
    <b-modal
      id="moveTo"
      :title="$t('MyJCI.070@move_to')"
      hide-footer
      class="modal-self"
    >
      <el-radio-group v-model="selected">
        <el-radio
          v-for="item in allAlbumList"
          :key="item.id"
          :label="item.id"
          >{{ item.title }}</el-radio
        >
      </el-radio-group>
      <button
        @click="confirmChooseAlbum"
        class="btn btn-primary d-block w-100 mt-3"
      >
        {{ $t("MyJCI.7d3@confirm") }}
      </button>
    </b-modal>
  </div>
</template>

<script>
import Albums from "./components/Album";
import Photo from "./components/Photo";
import DetPhoto from "./components/Photo";
import Video from "./components/Video";
export default {
  name: "Media",
  components: { Albums, Photo, DetPhoto, Video },
  props: ["photoList", "isHideIcon"],
  data() {
    return {
      is_album_detail: false,
      isShowNum: true,
      // addAlbum
      addAlbumData: {
        title: "",
        sort: "",
        // introduce: "",
      },
      albumList: [],
      addUpdateFlag: 0,
      albumInfo: {},

      // photo
      LandScape: "LandScape",
      albumDetailPhoto: [],

      // MOVE
      selected: 0,
      movePhotoId: 0,
      allAlbumList: [], //相册列表（名称/id）
      // 相册分页
      per_page: 4, //相册默认展示数量
      total: 12, //相册总数
      current_page: 1, //相册当前页

      photo_page: 12, //相片默认数量
      photoTotal: 12, //相片总数
      current_photo: 1, //相片当前页

      videoesList: [],
      totalVid: 0,
      currentVid: 1,
      album_id: "",
      loading: false,
      videoDetail: 0, //是否从详情跳至video
    };
  },
  mounted() {
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      this.addAlbumData = {};
    });
  },
  methods: {
    checkoutVideo() {
      this.is_album_detail = false;
      this.videoDetail = 1;
      this.getVideo();
    },
    async addVideo(params) {
      let formData = new FormData();
      formData.append("model_id", this.$route.query.model_id);
      formData.append("model_type", this.modelType);
      formData.append("media", params.file);
      let res = await this.$http.uploadFile(formData);
      if (res.status == 200) {
        await this.$http.addVideo({ url: res.data[0] }).then((res) => {
          if (res.status == 200) {
            this.currentVid = 1;
            this.getVideo();
          }
        });
      }
    },
    changePhoto(page) {
      this.current_photo = page;
      this.getAllPhoto();
    },
    changePage(page) {
      this.current_page = page;
      this.getAlbum();
    },
    addAlbum() {
      let userId = JSON.parse(localStorage.getItem("userInfo")).user_id;
      if (this.addAlbumData.title !== "" && this.addAlbumData.sort !== "") {
        this.$http
          .addAlbum({
            model_type: 1,
            model_id: userId,
            ...this.addAlbumData,
          })
          .then((res) => {
            if (res.status == 200) {
              this.getAlbum();
              this.$bvModal.hide("album");
            }
          });
      } else {
        this.$message.info(this.$t("MyJCI.844@incomplete_informati"));
      }
    },
    current_change(item) {
      this.currentVid = item;
      this.getVideo();
    },
    photoUpload(params) {
      this.loading = true;
      var formData = new FormData();
      formData.append("file", params.file);
      try {
        this.$http.uploadImage(formData).then((res) => {
          if (res.status == 200) {
            this.$http
              .addPhoto({
                url: res.data[0],
              })
              .then((res) => {
                if (res.status == 200) {
                  this.getAllPhoto();
                  this.loading = false;
                }
              });
          }
        });
      } catch (error) {
        this.loading = false;
      }
    },
    // 上传
    imgUpload(params) {
      this.loading = true;
      var formData = new FormData();
      formData.append("file", params.file);
      try {
        this.$http.uploadImage(formData).then((res) => {
          if (res.status == 200) {
            this.$http
              .addPhoto({
                album_id: this.album_id,
                url: res.data[0],
              })
              .then((res) => {
                if (res.status == 200) {
                  this.albumDet(this.album_id);
                  this.getAlbum();
                  this.getAllPhoto();
                  this.loading = false;
                }
              });
          }
        });
      } catch (error) {
        this.loading = false;
      }
    },

    queryEditAlbum(val) {
      this.$bvModal.show("album");
      this.addAlbumData = val;
      this.addUpdateFlag = 1;
      this.albumInfo = val;
    },

    updateAlbum(id) {
      this.$http
        .editAlbum({
          album_id: this.albumInfo.album_id,
          title: this.albumInfo.title,
          // introduce: this.albumInfo.introduce,
          sort: this.albumInfo.sort,
        })
        .then((res) => {
          if (res.status == 200) {
            this.$bvModal.hide("album");
          }
        });
    },

    confirmModal() {
      if (!this.addUpdateFlag) {
        // console.log("add");
        this.addAlbum();
      } else {
        // console.log("update");
        this.updateAlbum();
      }
    },

    albumDet(id) {
      this.album_id = id;
      this.is_album_detail = true;
      this.$http
        .albumPhotos({
          album_id: id,
        })
        .then((res) => {
          this.LandScape = res.data.title;
          this.albumDetailPhoto = res.data.photos.data;
        });
    },

    deleteAlbum(id) {
      this.$confirm(this.$t("MyJCI.dd7@this_operation_will_"), "Tips", {
        distinguishCancelAndClose: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.$http
          .delAlbum({
            album_id: id,
          })
          .then((res) => {
            if (res.status == 200) {
              this.getAlbum();
              this.getAllPhoto();
            }
          });
      });
    },

    // 获取该相片的所有照片详情
    queryAlbumDet(id) {
      this.$bvModal.show("album");
      this.addUpdateFlag = 1;
    },
    // setCover
    setCover(item) {
      this.$http
        .editPhoto({
          photo_path_id: item.photo_id,
          is_top: 1,
        })
        .then((res) => {
          // console.log(res);
          if (res.status == 200) {
            this.getAlbum();
          }
        });
    },
    allbumList() {
      let routerId = this.$route.query.user_id;
      let userId = JSON.parse(localStorage.getItem("userInfo")).user_id;
      let albumId = routerId ? routerId : userId;
      this.$http
        .allAlbumList({
          model_type: 1,
          model_id: albumId,
        })
        .then((res) => {
          if (res.status == 200) {
            console.log(res);
            this.allAlbumList = res.data;
          }
        });
    },
    getAlbum() {
      let routerId = this.$route.query.user_id;
      let userId = JSON.parse(localStorage.getItem("userInfo")).user_id;
      let albumId = routerId ? routerId : userId;
      this.$http
        .getAllAlbums({
          model_type: 1,
          model_id: albumId,
          page: this.current_page,
        })
        .then((res) => {
          if (res.status == 200) {
            this.albumList = res.data.data;
            this.total = res.data.total;
          }
        });
    },
    delVideos(item) {
      this.$confirm(this.$t("MyJCI.41d@this_operation_will_"), "Tips", {
        distinguishCancelAndClose: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        if (item.post_id) {
          this.$http.delUserPost({ user_post_id: item.post_id }).then((res) => {
            // console.log(res);
            if (res.status == 200) {
              this.currentVid = 1;
              this.getVideo();
            }
          });
        } else {
          this.$http.delVideos({ video_id: item.video_id }).then((res) => {
            if (res.status == 200) {
              this.currentVid = 1;
              this.getVideo();
            }
          });
        }
      });
    },
    // 删除照片---删除帖子
    deletePhoto(item) {
      this.$confirm(this.$t("MyJCI.41d@this_operation_will_"), "Tips", {
        distinguishCancelAndClose: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        if (item.post_id) {
          this.$http.delUserPost({ user_post_id: item.post_id }).then((res) => {
            // console.log(res);
            if (res.status == 200) {
              if (this.is_album_detail) {
                this.albumDet(this.album_id);
              }
              this.getAllPhoto();
            }
          });
        } else {
          this.$http.delPhotos({ photo_id: item.photo_id }).then((res) => {
            if (res.status == 200) {
              if (this.is_album_detail) {
                this.albumDet(this.album_id);
              }
              this.getAllPhoto();
            }
          });
        }
      });
    },
    // 获取视频
    getVideo() {
      this.videoesList = [];
      let selfId = JSON.parse(this.$getlocalStorage("userInfo")).user_id;
      let router_id = parseInt(this.$route.query.user_id);
      let id = router_id ? router_id : selfId;
      this.$http
        .getVideo({
          model_id: id,
          model_type: 1,
          page: this.currentVid,
        })
        .then((res) => {
          if (res.status == 200) {
            // this.videoesList = res.data.data;
            res.data.data.forEach((item) => {
              this.videoesList.push({ ...item, isPlay: 0 });
            });
            this.totalVid = res.data.total;
          }
        });
    },
    // 获取相片
    getAllPhoto() {
      let routerId = this.$route.query.user_id;
      let userId = JSON.parse(localStorage.getItem("userInfo")).user_id;
      let photoId = routerId ? routerId : userId;
      this.$http
        .getAllPhoto({
          model_type: 1,
          model_id: photoId,
          per_page: this.photo_page,
        })
        .then((res) => {
          if (res.status == 200) {
            this.photoList = res.data.data;
          }
        });
    },
    // move
    showMoveModal(id) {
      // console.log(555, id);
      this.$bvModal.show("moveTo");
      this.allbumList();
      this.movePhotoId = id;
    },

    // move
    confirmChooseAlbum() {
      // console.log("this.selected");
      if (!this.selected) return;
      this.$http
        .editPhoto({
          photo_path_id: this.movePhotoId,
          album_id: this.selected,
        })
        .then((res) => {
          // console.log(res);
          if (res.status == 200) {
            this.$bvModal.hide("moveTo");
            this.selected = "";
            this.getAlbum();
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .modal-content {
  margin-top: 100px;
}

::v-deep .nav-tabs .nav-link.active {
  border: none;
}

.album_detail {
  .album_path {
    display: flex;
    font-size: 16px;
    > li:first-child {
      color: #62bdff;
    }
    > li:nth-child(2) {
      padding: 0 5px;
    }
  }
}
</style>
