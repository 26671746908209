<template>
  <b-row class="row">
    <b-col v-for="(item, index) in albumsList" :key="index" md="6" lg="3" class="mb-3">
      <div class="album_list">
        <div class="album_cover" @click="toAlbumDetail(item.album_id)">
          <el-image
            class="img-fluid rounded cursor-pointer"
            :src="item.photo_path"
            fit="cover"
          >
            <div slot="error" class="image-slot">
              <img
                class="img-fluid rounded"
                src="./default.png"
              />
            </div>
          </el-image>
          <!-- <img v-if="item.photo_path" :src="item.photo_path" class="img-fluid rounded" />
          <img v-else src="./default.png" class="img-fluid rounded" /> -->
        </div>
        <h3>{{ item.title }}</h3>
        <ul v-if="item.is_operation != 1&&isHideIcon" class="p-0 m-0">
          <li @click="editAlbum(item)">{{$t('MyJCI.3b1@edit')}}</li>
          <li style="padding: 0 5px">|</li>
          <li @click="deleteAlbumFunc(item.album_id)">{{$t('MyJCI.649@delete')}}</li>
        </ul>
      </div>
    </b-col>
  </b-row>
</template>
<script>
  export default {
    name: 'album',
    props: ['albumsList', 'deleteAlbumFunc', "isHideIcon"],
    filters: {
      defaultImg(val) {
        return val ? val : './default.png'
      }
    },

    methods: {
      editAlbum(item) {
        this.$emit('editOpenModal', item);
      },
      toAlbumDetail(id) {
        this.$emit('toAlbumDet', id)
      },
    }

  }
</script>

<style scoped lang='scss'>
  .album_list {
    .album_cover {
      overflow: hidden;
      height: 150px;
      border-radius: 5px;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    &:hover {
      >ul {
        opacity: 1;
      }
    }

    >h3 {
      font-size: 14px;
    }

    >ul {
      display: flex;
      opacity: 0;

      >li {
        color: #50b5ff;
        cursor: pointer;
      }
    }
  }
</style>