<template>
  <div class="iq-card-body p-2">
    <div class="row" v-if="friendData.length > 0">
      <div
        class="col-md-12 col-lg-6 mb-3"
        v-for="(item, index) in friendData"
        :key="index"
      >
        <div class="iq-friendlist-block">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <div class="imgbox" @click="refresh(item.user_id)">
                <img :src="item.avatar" alt="profile-img" class="" />
              </div>
              <div class="friend-info">
                <h5 style="overflow: hidden;text-overflow: ellipsis;" :title="item.nick_name">{{ item.nick_name }}</h5>
                <!-- <p class="mb-0">Birth:{{ item.birthday }}</p> -->
                <p
                  class="mb-0"
                  v-if="item.region.length == '2' && item.organization"
                >
                  {{ item.region[0] + " " + item.region[1] }}/{{
                    item.organization
                  }}
                </p>
                <p
                  class="mb-0"
                  v-else-if="item.region.length == '1' && item.organization"
                >
                  {{ item.region[0] }}/{{ item.organization }}
                </p>
                <p
                  class="mb-0"
                  v-else-if="item.region.length == '0' && item.organization"
                >
                  {{ item.organization }}
                </p>
                <p
                  class="mb-0"
                  v-else-if="item.region.length == '2' && !item.organization"
                >
                  {{ item.region[0] + " " + item.region[1] }}
                </p>
                <p
                  class="mb-0"
                  v-else-if="item.region.length == '1' && !item.organization"
                >
                  {{ item.region[0] }}
                </p>
              </div>
            </div>
            <div class="iq-card-header-toolbar d-flex align-items-center">
              <span
                v-if="isHideIcon"
                @click="confirmDel(item.user_id, index)"
                class="dropdown-toggle btn btn-secondary mr-2"
                id="dropdownMenuButton01"
                data-toggle="dropdown"
                aria-expanded="true"
                role="button"
              >
                <i class="ri-check-line mr-1 text-white font-size-16"></i>
                Friend
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else style="background:#fff;padding:30px 0;" class="d-flex justify-content-center mt-2">
      <img style="width:300px" src="@/assets/images/group/empty1.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  props: {
    friendData: {
      type: Array,
      default: [],
    },
    isHideIcon: "",
  },
  components: {},

  created() {},

  methods: {
    refresh(user_id) {
      this.$router.push({ path: "/profile?user_id=" + user_id });
      location.reload();
    },
    // 删除好友
    delFriend(user_id, index) {
      this.$http.delFriend({ user_id }).then((res) => {
        if (res.status == 200) {
          this.friendData.splice(index, 1);
        }
      });
    },
    // 删除好友关系时询问
    confirmDel(user_id, index) {
      this.$confirm("Are you sure you want to delete?", "Tips", {
        distinguishCancelAndClose: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.delFriend(user_id, index);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Deletion cancelled",
          });
        });
    },
  },
};
</script>
<style lang="less" scoped>
.iq-card-body{
  max-height: 600px;
  overflow-y: auto;
}
@media (min-width: 768px) {
  .imgbox {
    width: 150px !important;
    height: 150px !important;
    img {
      width: 150px;
      height: 150px;
    }
  }
  
  .friend-info{
    padding-left: 1rem;
    width: 180px;
    h5{
      margin-bottom: 1rem;
      width: 180px;
    }
  }
}
@media (max-width: 768px) {
  .imgbox {
    width: 100px !important;
    height: 100px !important;
    img {
      width: 100px;
      height: 100px;
    }
  }
  .friend-info{
    width: 100px;
    padding-left: 5px;
    h5{
      margin-bottom: 5px;
      width: 110px;
    }
    p {
      width: 110px;
      line-height: 1.2;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; //行数
      display: -webkit-box;
    }
  }
  .btn{
    font-size: 10px !important;
  }
}
</style>
