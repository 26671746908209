<template>
  <div class="row About">
    <div class="col-md-3 col-3">
      <tab-nav :pills="true" id="tab" class="nav nav-pills basic-info-items list-inline d-block p-0 m-0">
        <tab-nav-items v-show="isHideIcon" :active="true" id="pills-family-info" href="#family-info"
          ariaControls="pills-family-info" role="tab" :ariaSelected="true" :title="$t('MyJCI.eff@contact_basic')" />
        <!-- <tab-nav-items
          :active="false"
          id="pills-contact-info"
          href="#contact-info"
          ariaControls="pills-contact-info"
          role="tab"
          :ariaSelected="false"
          title="Hashtags"
        /> -->
        <tab-nav-items :active="false||showCareer" id="pills-contact-info" href="#career"
          ariaControls="pills-contact-info" role="tab" :ariaSelected="true" :title="$t('MyJCI.007@jci_career')"
          @click.native="getCareerList" />

        <!-- <tab-nav-items :active="false" id="pills-work-info" href="#work-info" ariaControls="pills-work-info" role="tab"
          :ariaSelected="false" title="Hashtag" @click.native="userHobbies()" /> -->

        <tab-nav-items :active="false" id="pills-address-info" href="#address-info" ariaControls="pills-address-info"
          role="tab" :ariaSelected="false" :title="$t('MyJCI.923@working_experiences')" @click.native="experience()" />

        <tab-nav-items :active="false" id="pills-about-info" href="#about-info" ariaControls="pills-about-info"
          role="tab" :ariaSelected="false" :title="$t('MyJCI.f50@education')" @click.native="education()" />

        <tab-nav-items :active="false" id="pills-about-info" href="#skill" ariaControls="pills-about-info" role="tab"
          :ariaSelected="false" :title="$t('MyJCI.803@skills_sets')" @click.native="skill" />
      </tab-nav>
    </div>
    <div class="col-md-9 pcPadding col-9">
      <div class="tab-content">
        <!-- Contact and Basic Info -->
        <tab-content-item v-show="isHideIcon" :active="true" id="family-info" aria-labelled-by="pills-family-info">
          <ContactAndBasicInfo ref="userInfoDet" :isHideIcon="isHideIcon" />
        </tab-content-item>
        <!-- Details About you -->
        <!-- <tab-content-item
          :active="false"
          id="contact-info"
          aria-labelled-by="pills-contact-info"
        >
          <DetailsAboutYou :list="list" :isHideIcon="isHideIcon" />
        </tab-content-item> -->
        <tab-content-item :active="false||showCareer" id="career" aria-labelled-by="pills-contact-info">
          <!-- <Career :careerList="careerList" :isHideIcon="isHideIcon"/> -->
          <Career :isHideIcon="isHideIcon" ref="career" />
        </tab-content-item>
        <!-- Hobby -->
        <!-- <tab-content-item :active="false" id="work-info" aria-labelled-by="pills-work-info">
          <Hobby ref="hobbies" :isHideIcon="isHideIcon" />
        </tab-content-item> -->
        <!-- Experience -->
        <tab-content-item :active="false" id="address-info" aria-labelled-by="pills-address-info">
          <Experience ref="experience" :isHideIcon="isHideIcon" />
        </tab-content-item>
        <!-- Education -->
        <tab-content-item :active="false" id="about-info" aria-labelled-by="pills-about-info">
          <Education ref="education" :isHideIcon="isHideIcon" />
        </tab-content-item>
        <!-- Skills&Endorsements -->
        <tab-content-item :active="false" id="skill" aria-labelled-by="pills-about-info">
          <SkillsEndorsements ref="skill" :isHideIcon="isHideIcon" />
        </tab-content-item>
        <tab-content-item :active="false" id="skill" aria-labelled-by="pills-about-info">
          <h4 class="mt-3 mb-3">{{ $t("MyJCI.eb0@skill") }}</h4>
          <hr />
          <ul class="suggestions-lists m-0 p-0">
            <li class="d-flex mb-4 align-items-center">
              <div class="user-img img-fluid"><i class="ri-add-fill"></i></div>
              <div class="media-support-info ml-3">
                <h6 v-b-modal.modal-1>{{ $t("MyJCI.d38@add_skill") }}</h6>
              </div>
            </li>
          </ul>
          <p style="font-size: 16px; font-weight: 500">
            {{ $t("MyJCI.ffe@project_planning") }}
          </p>
          <hr />
          <p style="font-size: 16px; font-weight: 500">
            {{ $t("MyJCI.0dd@project_management") }}
          </p>
          <hr />
          <p style="font-size: 16px; font-weight: 500">
            Python(Programming Language)
          </p>
          <hr />
        </tab-content-item>
      </div>
    </div>
  </div>
</template>
<script>
  import DetailsAboutYou from "./components/DetailsAboutYou";
  import ContactAndBasicInfo from "./components/ContactAndBasicInfo";
  import Career from "./components/Career";
  import Education from "./components/Education";
  import Experience from "./components/Experience";
  import Hobby from "./components/Hobby";
  import SkillsEndorsements from "./components/SkillsEndorsements";
  export default {
    name: "About",
    components: {
      DetailsAboutYou,
      Career,
      ContactAndBasicInfo,
      Education,
      Experience,
      Hobby,
      SkillsEndorsements,
    },
    props: ["isHideIcon"],
    data() {
      return {
        careerList: [],
        list: [],
        showCareer: false
      };
    },
    mounted() {
      let selfId = JSON.parse(this.$getlocalStorage("userInfo")).user_id;
      let router_id = parseInt(this.$route.query.user_id);
      let id = "";
      if (router_id == selfId || !router_id) {
        this.showCareer = false
      } else {
        this.showCareer = true
      }
    },
    methods: {
      getUser(){
        this.$refs.userInfoDet.getUserInfo();
      },
      userHobbies() {
        this.showCareer = false
        this.$refs.hobbies.userHobbies();
      },
      education() {
        this.showCareer = false
        this.$refs.education.getEducationList();
      },
      skill() {
        this.showCareer = false
        this.$refs.skill.getCompletedCourse();
      },
      experience() {
        this.showCareer = false
        this.$refs.experience.allResquest();
      },
      getCareerList() {
        // let router_id = parseInt(this.$route.query.user_id);
        // this.$http
        //   .professionalTitlesList({ user_id: router_id ? router_id : "" })
        //   .then((res) => {
        //     if (res.status == 200) {
        //       this.careerList = res.data;
        //     }
        //   });
        this.$refs.career.getUserJobTitleSingle()
      },
      // Aboutdetails中的用户标签
      UserTag() {
        let router_id = parseInt(this.$route.query.user_id);
        this.$http
          .UserTag({ user_id: router_id ? router_id : "" })
          .then((res) => {
            if (res.status == 200) {
              this.list = res.data;
            }
          });
      },
    },
  };
</script>
<style lang="scss" scoped>
@media (max-width: 768px) {
  .pcPadding {
    padding-left: 1.5rem !important;
  }
}
</style>