<template>
  <b-row class="row" v-if="photoListData.length>0">
    <b-col
      v-for="(item, index) in photoListData"
      :key="index"
      md="6"
      lg="3"
      class="mb-3"
    >
      <div
        class="user-images position-relative overflow-hidden photo_self_size"
      >
        <!-- <img
          :src="item.path"
          class="img-fluid rounded h-100"
          alt="Responsive image"
        /> -->
        <el-image :src="item.path" fit="cover" @click="previewImg(item.path)" :preview-src-list="srcList" class="img-fluid rounded h-100 w-100">
          <div slot="error" class="image-slot">
            <img src="../../../../../assets/images/public/fail.png" />
          </div>
        </el-image>
        <section v-if="isShowNum">
          <div class="image-hover-data" v-if="item.post_id">
            <div class="product-elements-icon">
              <ul class="d-flex align-items-center m-0 p-0 list-inline">
                <li class="pr-3 text-white">
                  {{ item.like_total }} <i class="ri-thumb-up-line"></i>
                </li>
                <li class="pr-3 text-white">
                  {{ item.comment_total }} <i class="ri-chat-3-line"></i>
                </li>
                <li class="pr-3 text-white">
                  {{ item.share_total }} <i class="ri-share-forward-line"></i>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <div class="photo_edit" v-if="isHideIcon">
          <b-dropdown
            dropleft
            size="sm"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <div class="edit_pen">
                <i class="las la-pen m-0"></i>
              </div>
            </template>
            <b-dropdown-item @click="deletePhotoFunc(item)"
              >{{$t('MyJCI.649@delete')}}</b-dropdown-item
            >
            <b-dropdown-item @click="showMoveModalFunc(item.photo_id)"
              >{{$t('MyJCI.070@move_to')}}</b-dropdown-item
            >
            <b-dropdown-item @click="setCoverFunc(item)"
              >{{$t('MyJCI.c92@set_as_cover')}}</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
    </b-col>
  </b-row>
  <div v-else class="d-flex justify-content-center mt-5 mb-5">
    <!-- <img style="width:250px;" src="@/assets/images/group/empty1.png" alt="" /> -->
  </div>
</template>
<script>
export default {
  props: [
    "photoListData",
    "setCoverFunc",
    "deletePhotoFunc",
    "showMoveModalFunc",
    "isHideIcon",
    "isShowNum"
  ],
  data () {
    return {
      srcList:[]
    };
  },
  created () { },
  methods: {
    previewImg(path){
      this.srcList = [];
      this.srcList.push(path);
    }
  },
};
</script>

<style lang="scss" scoped>
.photo_self_size {
  height: 165px;
  &:hover .edit_pen {
    opacity: 1;
  }
  > img {
    display: block;
    width: 100%;
    height: auto;
  }
}
.el-image{
  background: #eee;
    >.image-slot{
      width: 100%;
      height: 100%;
      position: relative;
      >img{
        position: absolute;
        top: 40%;
        left: 40%;
      }
    }
}
.photo_edit {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;

  .edit_pen {
    padding: 3px 5px;
    border-radius: 5px;
    background: #fff;
    opacity: 0;
    i {
      font-size: 16px;
      color: #55b9ff;
      font-weight: 600;
    }
  }
}
</style>
