<template>
  <section class="Experience">
    <h4 class="mb-3">Work</h4>
    <hr />
    <ul class="suggestions-lists m-0 p-0">
      <li class="d-flex mb-4 align-items-center" v-if="isHideIcon">
        <div class="user-img img-fluid"><i class="ri-add-fill"></i></div>
        <div class="ml-3 cursor-pointer">
          <h6 @click="handleOpenWorkType('add')">Add Work Experience</h6>
        </div>
      </li>
    </ul>
    <ul class="suggestions-lists m-0 p-0" v-if="workExperience.length > 0">
      <li v-for="(v, i) in workExperience" style="margin-bottom: 40px" :key="i" class="d-flex">
        <!-- Experience list -->
        <div class="media-support-info">
          <div class="d-flex mb-2 align-items-center">
            <div class="user-img img-fluid"></div>
            <div class="media-support-info">
              <div class="comName" :title="v.company_name">
                {{ v.company_name }}
              </div>
              <div class="comDes" :title="v.industry">
                {{ v.industry }}
              </div>
              <div class="comDes" :title="v.company_website">
                {{v.company_website}}
              </div>
              <!-- <p class="mb-0">{{ v.diff_str_date }}</p> -->
            </div>
          </div>
          <div class="col-12 p-0">
            <div class="jobTit" :title="v.work_title">{{ v.work_title }}</div>
            <p class="mb-0 workDate" :title="v.diff_str_date">
              <span v-if="v.diff_str_date">{{ v.diff_str_date }}</span>
              <span v-else>{{ v.work_start_time }}-{{ $t("MyJCI.e12@right_now") }}</span>
            </p>
            <div class="jobDes" :title="v.job_description">{{ v.job_description }}</div>
          </div>
        </div>
        <div class="edit-relation position-relative text-right" v-if="isHideIcon">
          <!-- v-b-modal.ExperienceWork -->
          <button type="button" class="btn btn-primary h-100 position-absolute left-0 top-0 opacity"
            @click="handleOpenWorkType('edit', v.id)"></button>
          <a href="javascript:;" @click="handleOpenWorkType('edit', v.id)"><i class="ri-edit-line mr-2"></i>{{ $t("MyJCI.3b1@edit") }}</a>
        </div>
      </li>
    </ul>
    <div class="empty" v-else> </div>
    <div class="Project">{{ $t("Headbar.015@project") }}</div>
    <hr />
    <ul class="suggestions-lists m-0 p-0">
      <li class="d-flex mb-4 align-items-center" v-if="isHideIcon">
        <div class="user-img img-fluid"><i class="ri-add-fill"></i></div>
        <div class="ml-3">
          <h6 class="cursor-pointer" @click="handleOpenProjectType('add')">
            {{ $t("MyJCI.234@add_project_experien") }}
          </h6>
        </div>
      </li>
    </ul>
    <ul v-if="projectList.data&&(projectList.data.length > 0)" class="suggestions-lists m-0 p-0">
      <li class="d-flex" style="margin-bottom: 40px" v-for="(v, i) in projectList.data" :key="i">
        <div class="media-support-info">
          <h6 class="longText addProIcon comName" :title="v.project_name">
            {{ v.project_name }}
          </h6>
          <p class="mb-0">{{ v.start_time + " " + "-" + " " + v.end_time }}</p>
          <p class="mt-2 longText des" :title="v.description">
            {{ v.description }}
          </p>
        </div>

        <div class="edit-relation position-relative" v-if="isHideIcon">
          <button type="button" class="
              w-100
              btn btn-primary
              h-100
              position-absolute
              left-0
              top-0
              opacity
            " @click="handleOpenProjectType('edit', v.id)"></button>
          <a @click="handleOpenProjectType('edit', v.id)" href="javascript:;"><i class="ri-edit-line mr-2"></i>{{ $t("MyJCI.3b1@edit") }}</a>
        </div>
      </li>
    </ul>
    <div class="empty" v-else> </div>
    <!-- ExperienceWork 弹窗 -->
    <b-modal id="ExperienceWork" centered title="Experience(Work)" hide-footer @hidden="hideModalWork">
      <section>
        <div class="form-group">
          <label>{{ $t("MyJCI.479@job_position") }}
            <span class="star">*</span></label>
          <input type="text" class="form-control" :placeholder="$t('MyJCI.e42@as:sales_manager')"
            v-model="work.work_title" />
        </div>
        <div class="form-group">
          <label>Job Description</label>
          <textarea class="form-control"  rows="4" v-model="work.job_description"></textarea>
        </div>
        <div class="form-group">
          <label>{{ $t("MyJCI.758@company_name") }}
            <span class="star">*</span></label>
          <input type="text" class="form-control" v-model="work.company_name" />
        </div>
        <div class="form-group">
          <label>Company Website</label>
          <input type="text"  class="form-control" v-model="work.company_website" />
        </div>
        <div class="form-group">
          <label >Industry</label>
          <input type="text" class="form-control"  v-model="work.industry" />
        </div>
        <div class="form-group form-check d-flex align-items-center">
          <input type="checkbox" class="form-check-input mr-3 mb-1" id="serveAs" v-model="work.is_current" />
          <label class="form-check-label" for="serveAs">I currently work in this role</label>
        </div>
        <!-- 开始时间结束时间 -->
        <div class="row">
          <div class="col-6 borderNone">
            <label>{{ $t("MyJCI.f5f@start_date")
              }}<span class="star">*</span></label>
            <el-date-picker style="width: 100%" v-model="work.work_start_time" type="date"
              :placeholder="$t('MyJCI.f5f@start_date')" value-format="yyyy-MM-dd"
              :editable="false">
            </el-date-picker>
          </div>
          <div v-if="!work.is_current" class="col-6 borderNone">
            <label>{{ $t("MyJCI.c5c@end_date") }} <span class="star">*</span></label>
            <el-date-picker style="width: 100%" v-model="work.work_end_time" type="date"
              :placeholder="$t('MyJCI.c5c@end_date')"  value-format="yyyy-MM-dd"
              :editable="false">
            </el-date-picker>
          </div>
        </div>
        <div class="mt-3">
          <a @click="userDelWork(work.id)" v-if="delet" href="javascript:;" style="color: #ef5959"><u>{{
              $t("MyJCI.649@delete") }}</u></a>
          <b-button variant="primary"  class="float-right" v-preventRe @click="handleWorkSubmit">{{ $t("MyJCI.7d3@confirm") }}
          </b-button>
          <b-button class="float-right mr-2 btnOrange" @click="$bvModal.hide('ExperienceWork')">{{
            $t("MyJCI.d99@cancel") }}</b-button>
        </div>
      </section>
    </b-modal>
    <!-- Project 弹窗 -->
    <b-modal id="ExperienceProject" centered :title="project.popupTitle" hide-footer @hidden="hideModalProject">
      <div class="form-group">
        <label>{{ $t("MyJCI.08f@project_title") }}</label>
        <input type="text" class="form-control" v-model="project.project_name" />
      </div>
      <div class="row">
        <div class="col-6 borderNone">
          <label>{{ $t("MyJCI.b0a@project_time") }}</label>
          <el-date-picker style="width: 100%" v-model="project.start_time" type="date" placeholder="Start Date"
            :default-value="DefaultDate" value-format="yyyy-MM-dd" :editable="false">
          </el-date-picker>
        </div>
        <div class="col-6 d-flex align-items-end w-100 borderNone">
          <el-date-picker style="width: 100%" v-model="project.end_time" type="date" placeholder="End Date"
            :default-value="DefaultDate" value-format="yyyy-MM-dd" :editable="false">
          </el-date-picker>
        </div>
      </div>
      <div class="form-group mt-3">
        <label for="exampleFormControlTextarea1">{{
          $t("MyJCI.777@description")
          }}</label>
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
          v-model="project.description"></textarea>
      </div>
      <hr />
      <a @click="userDelProject(project.id)" v-if="delet" href="javascript:;" style="color: #ef5959"><u>{{
          $t("MyJCI.649@delete") }}</u></a>
      <b-button variant="primary" class="float-right mb-2" v-preventRe @click="handleProjectSubmit">{{ $t("MyJCI.7d3@confirm") }}
      </b-button>
      <b-button class="float-right btnOrange mr-2 mb-2" @click="$bvModal.hide('ExperienceProject')">{{
        $t("MyJCI.d99@cancel") }}</b-button>
    </b-modal>
  </section>
</template>
<script>
export default {
  name: 'Experience',
  components: {},
  data () {
    return {
      isAddWork: false,
      isAddProject: false,

      // allWork: [], // 历史工作经验
      projectList: [], // 项目经验列表
      workExperience: [], // 工作经验列表
      isHideIcon: false,
      delet: false, // 删除按钮
      work: {
        popupType: '', // 工作经验弹窗打开类型  => add , edit
        work_title: '', // 职位名称
        company_name: '', // 公司名称
        is_current: false, // 是否担任 这个职位
        work_start_time: '', // 开始时间
        work_end_time: '', // 结束时间
        work_experience_id: '',
        job_description: '',
        company_website: '',
        industry: ''
      },
      project: {
        project_experience_id: '', // id => 获取单个项目详情
        popupType: '', // 项目经验弹窗打开类型  => add , edit
        popupTitle: '', // 弹窗名字
        project_name: '', // 项目名称
        start_time: '', // 开始时间
        end_time: '', // 结束时间
        description: '' // 描述
      }
    }
  },
  computed: {
    // 日历插件的默认日期
    DefaultDate () {
      let date = new Date()
      let y = date.getFullYear()
      return new Date(`${y}-01-01`)
    }
  },
  watch: {},
  created () { },
  methods: {
    // 监听work关闭
    hideModalWork (bvModalEvt, modalId) {
      if (this.isAddWork) {
        this.work.work_title = ''
        this.work.company_name = ''
        this.work.work_start_time = ''
        this.work.work_end_time = ''
        this.work.job_description = ''
        this.work.company_website = ''
        this.work.industry = ''
      } else {
        if (
          !this.work.work_title &&
            !this.work.company_name &&
            !this.work.work_start_time &&
            !this.work.work_end_time
        ) { return }
        this.$confirm(
          'Close the pop-up box, the form will be cleared, are you sure to continue?',
          'Tips',
          {
            distinguishCancelAndClose: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        )
          .then(() => { })
          .catch(() => {
            this.$bvModal.show('ExperienceWork')
          })
      }
    },
    // 监听project关闭
    hideModalProject (bvModalEvt, modalId) {
      if (this.isAddProject) {
        this.project.project_name = ''
        this.project.start_time = ''
        this.project.end_time = ''
        this.project.description = ''
      } else {
        if (
          !this.project.project_name &&
            !this.project.start_time &&
            !this.project.end_time &&
            !this.project.description
        ) { return }
        this.$confirm(
          'Close the pop-up box, the form will be cleared, are you sure to continue?',
          'Tips',
          {
            distinguishCancelAndClose: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        )
          .then(() => { })
          .catch(() => {
            this.$bvModal.show('ExperienceProject')
          })
      }
    },
    // 父级调用
    allResquest () {
      this.listUserProject()
      this.listWorkExperience()
      // this.getAllWork();
    },
    /// /////////////////////////////// 工作经验处理 //////////////////////
    // 判断工作经验 操作类型
    async handleOpenWorkType (type, work_experience_id) {
      this.work = {}
      this.work.popupType = type
      this.work_experience_id = work_experience_id
      if (type === 'add') {
        this.isAddWork = false
        this.delet = false
        this.$bvModal.show('ExperienceWork')
      } else {
        this.isAddWork = true
        this.$bvModal.show('ExperienceWork')
        this.delet = true
        this.firstWorkExperience(work_experience_id)
      }
    },
    // 获取历史工作经验列表
    // getAllWork() {
    //   let router_id = parseInt(this.$route.query.user_id);
    //   this.$http
    //     .getAllWork({ user_id: router_id ? router_id : "" })
    //     .then((res) => {
    //       if (res.status == 200) {
    //         this.allWork = res.data;
    //         // console.log("工作经验列表", res.data)
    //       }
    //     });
    // },
    // 新增工作经验
    userAddWork () {
      if (this.work.is_current) {
        this.work.is_current = 1
      } else {
        this.work.is_current = 0
      }
      const data = JSON.parse(JSON.stringify(this.work))
      if (data.company_name && data.work_title && data.work_start_time) {
        this.$http.userAddWork(data).then((res) => {
          if (res.status == 200) {
            this.isAddWork = true
            this.$bvModal.hide('ExperienceWork')
            this.listWorkExperience()
          }
        })
      } else {
        this.$message.warning('Missing information!')
      }
    },
    // 工作经验列表
    listWorkExperience () {
      let selfId = JSON.parse(this.$getlocalStorage('userInfo')).user_id
      let router_id = parseInt(this.$route.query.user_id)
      let id = ''
      if (router_id == selfId || !router_id) {
        id = ''
        this.isHideIcon = true
      } else {
        id = router_id
        this.isHideIcon = false
      }
      this.$http.listWorkExperience({ user_id: id }).then((res) => {
        if (res.status == 200) {
          let result = JSON.parse(JSON.stringify(res.data))
          this.workExperience = res.data
          result.forEach((v, i) => { })
          this.WorkExperience = result
        }
      })
    },
    // 编辑工作经验
    userEditWork () {
      const data = JSON.parse(JSON.stringify(this.work))
      if (data.is_current == true) {
        data.is_current == 1
      } else {
        data.is_current == 0
      }
      if (data.company_name && data.work_title && data.work_start_time) {
        this.$http
          .userEditWork({
            work_experience_id: data.id,
            work_start_time: data.work_start_time,
            work_end_time: data.work_end_time,
            work_title: data.work_title,
            is_current: data.is_current,
            company_name: data.company_name,
            job_description: data.job_description,
            company_website: data.company_website,
            industry: data.industry
          })
          .then((res) => {
            if (res.status == 200) {
              this.listWorkExperience()
              this.$bvModal.hide('ExperienceWork')
            }
          })
      } else {
        this.$message.warning('Missing information!')
      }
    },
    // 获取单条工作经验信息
    firstWorkExperience (work_experience_id) {
      this.$http.firstWorkExperience({ work_experience_id }).then((res) => {
        if (res.status == 200) {
          this.work = res.data
          if (res.data.is_current == 1) {
            this.work.is_current == true
          } else {
            this.work.is_current == false
          }
        }
      })
    },
    // 删除工作经历
    userDelWork (work_experience_id) {
      this.$http.userDelWork({ work_experience_id }).then((res) => {
        if (res.status == 200) {
          this.$bvModal.hide('ExperienceWork')
          this.listWorkExperience()
        }
      })
    },
    // 工作经验提交
    handleWorkSubmit () {
      this.work.popupType == 'add' ? this.userAddWork() : this.userEditWork()
      this.listWorkExperience()
      this.picIsShow = false
    },

    /// /////////////////////////////// 项目经验处理 //////////////////////
    // 获取项目经验列表
    listUserProject () {
      let selfId = JSON.parse(this.$getlocalStorage('userInfo')).user_id
      let router_id = parseInt(this.$route.query.user_id)
      let id = ''
      if (router_id == selfId || !router_id) {
        id = ''
      } else {
        id = router_id
      }
      this.$http.listUserProject({ user_id: id }).then((res) => {
        if (res.status == 200) {
          this.projectList = res.data
        }
      })
    },
    // 判断项目经验 操作类型
    handleOpenProjectType (type, id) {
      this.project = {}
      this.project.popupType = type
      if (type === 'add') {
        this.isAddProject = false
        this.delet = false
        this.$bvModal.show('ExperienceProject')
        this.project.popupTitle = 'Add Project'
      } else {
        this.isAddProject = true
        this.delet = true
        this.$bvModal.show('ExperienceProject')
        this.projectExperienceFind(id)
        this.project.popupTitle = 'Edit Project'
      }
    },
    // 获取项目经验详情
    projectExperienceFind (project_experience_id) {
      this.project.project_experience_id = project_experience_id
      this.$http
        .projectExperienceFind({ project_experience_id })
        .then((res) => {
          if (res.status == 200) {
            this.project = { ...this.project, ...res.data }
          }
        })
    },
    // 添加项目经验
    userAddProject () {
      const data = JSON.parse(JSON.stringify(this.project))
      delete data.popupType
      delete data.popupTitle
      if (
        data.project_name &&
          data.start_time &&
          data.end_time &&
          data.description
      ) {
        this.$http.userAddProject(data).then((res) => {
          if (res.status == 200) {
            this.listUserProject()
            this.isAddProject = true
            this.$bvModal.hide('ExperienceProject')
          }
        })
      } else {
        this.$message.warning('Missing information!')
      }
    },
    // 编辑项目经验
    userEditProject () {
      const data = JSON.parse(JSON.stringify(this.project))
      delete data.popupType
      delete data.popupTitle
      delete data.created_at
      delete data.id
      data.project_experience_id = this.project.project_experience_id
      if (
        data.project_name &&
          data.start_time &&
          data.end_time &&
          data.description
      ) {
        this.$http.userEditProject(data).then((res) => {
          if (res.status == 200) {
            this.listUserProject()
            this.$bvModal.hide('ExperienceProject')
          }
        })
      } else {
        this.$message.warning('Missing information!')
      }
    },
    userDelProject (project_experience_id) {
      this.$http.userDelProject({ project_experience_id }).then((res) => {
        if (res.status == 200) {
          this.listUserProject()
          this.$bvModal.hide('ExperienceProject')
        }
      })
    },
    // 项目经验 提交 => add  edit
    handleProjectSubmit () {
      this.project.popupType == 'add'
        ? this.userAddProject()
        : this.userEditProject()
      this.listUserProject()
    }
  }
}
</script>
<style lang="scss" scoped>
  @media screen and (min-width: 992px) {
    ::v-deep #ExperienceWork .modal-dialog {
      max-width: 800px !important;
    }

    ::v-deep #ExperienceProject .modal-dialog {
      max-width: 800px !important;
    }
  }
  @media (min-width: 600px) {
    .longText{
      max-width: 500px;
    }
    .comName, .comDes, .jobDes{
      max-width: 600px;
    }
    .workDate{
      max-width: 500px;
    }
    .jobTit {
      max-width: 360px;
    }
  }
  @media (max-width: 600px) {
    .longText, .comName, .comDes, .jobDes, .workDate, .jobTit{
      max-width: 180px;
    }
  }
  .Project {
    margin-top: 65px;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 400;
    color: #333333;
  }

  .addProIcon {
    margin-top: 20px;
    margin-bottom: 28px;
  }

  ::v-deep #ExperienceWork .modal-dialog {
    margin: 5rem auto;
  }

  .empty {
    display: flex;
    justify-content: center;
    margin: 60px 0;
  }

  .longText {
    overflow: hidden;
    word-break: keep-all;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .Experience {
    .opacity {
      opacity: 0;
    }

    .comName {
      font-size: 16px;
      color: #000;
      overflow: hidden;
      word-break: keep-all;
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .comDes{
      font-size: 14px;
      overflow: hidden;
      word-break: keep-all;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .jobDes {
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      word-break: keep-all;
    }

    :hover {
      cursor: pointer;
    }

    .des {
      color: #666;
    }

    .jobTit {
      font-size: 14px;
      color: #000;
      overflow: hidden;
      word-break: keep-all;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .workDate {
      font-size: 14px;
      color: #999999;
      overflow: hidden;
      word-break: keep-all;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  // popup
  #ExperienceWork {
    .star {
      color: red;
    }

    .EndThisPosition {
      color: #3f414d;
      line-height: 26px;
    }

    .imgBox {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 20px;

      img {
        border-radius: 50%;
        width: 100%;
      }
    }

    .title {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }

    .info {
      color: #999999;
    }

    .modalMedia,
    .desc {
      color: #3f414d;
      line-height: 26px;
    }
  }

  ::v-deep .borderNone {
    .form-control {
      .form-control {
        border: 0;
        line-height: 33px;
      }
    }
  }

  textarea {
    height: 50px !important;
    line-height: 35px !important;
  }

  .exprience-list {
    >li {
      position: relative;
      margin-bottom: 15px;

      >h5 {
        color: #333;
      }

      p {
        color: #999;
      }

      // 线
      &::before {
        content: "";
        display: block;
        width: 1px;
        height: 130%;
        background-color: #e8e8e8;
        position: absolute;
        top: 12px;
        left: -39px;
      }

      // 球
      &::after {
        content: "";
        display: block;
        width: 6px;
        height: 6px;
        background: #e8e8e8;
        border-radius: 50%;
        position: absolute;
        top: 12px;
        left: -42px;
      }

      // 如果是最后一个li就清除掉：：before
      &:last-child {
        &::before {
          display: none;
        }
      }
    }
  }

  .el-date-editor::v-deep {
    .el-input__inner {
      border-radius: 10px;
      border: 1px solid #dcdfe6;
      height: 45px;
    }
  }
</style>
