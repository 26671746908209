<template>
  <b-row class="row" v-if="videoList.length > 0">
    <b-col
      v-for="(item, index) in videoList"
      :key="index"
      md="6"
      lg="3"
      class="mb-3"
    >
      <div class="user-images position-relative">
          <video
            :src="item.path"
            :id="item.video_id"
            style="width:100%;height:100%;border-radius:5px;object-fit:cover"
            preload controls
            alt="Responsive image"
          />
        <!-- <i
          v-if="item.isPlay==1"
          class="ri-pause-circle-line playIcon"
          @click="pauseVideo(item)"
        ></i>
        <i
          v-else
          class="ri-play-circle-line playIcon"
          @click="playVideo(item)"
        ></i> -->
        <div class="image-hover-data" v-if="item.post_id">
          <div class="product-elements-icon">
            <ul class="d-flex align-items-center m-0 p-0 list-inline">
              <li>
                <a href="#" class="pr-3 text-white">
                  {{ item.like_total }} <i class="ri-thumb-up-line"></i>
                </a>
              </li>
              <li>
                <a href="#" class="pr-3 text-white">
                  {{ item.comment_total }} <i class="ri-chat-3-line"></i>
                </a>
              </li>
              <li>
                <a href="#" class="pr-3 text-white">
                  {{ item.share_total }} <i class="ri-share-forward-line"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="photo_edit">
          <b-dropdown
            dropleft
            size="sm"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <div class="edit_pen" v-if="isHideIcon">
                <i class="las la-pen m-0"></i>
              </div>
            </template>
            <b-dropdown-item @click="deleteVideoFunc(item)">{{
              $t("MyJCI.649@delete")
            }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </b-col>
  </b-row>
  <div v-else class="d-flex justify-content-center mt-5 mb-5">
    <!-- <img style="width:250px;" src="@/assets/images/group/empty1.png" alt="" /> -->
  </div>
</template>
<script>
import { socialvue } from "@/config/pluginInit";
export default {
  mounted() {
    socialvue.index();
  },
  props: ["videoList", "deleteVideoFunc", "isHideIcon"],
  data() {
    return {
    };
  },
  methods: {
    playVideo(item) {
      var video = document.getElementById(item.video_id);
      video.play();
      item.isPlay=1;
    },
    pauseVideo(item) {
      var video = document.getElementById(item.video_id);
      video.pause();
      item.isPlay=0;
    },
  },
};
</script>
<style lang="scss" scoped>
.user-images {
  height: 165px;
  border-radius: 5px;
  &:hover .edit_pen {
    opacity: 1;
  }
}
.playIcon {
  position: absolute;
  left: 95px;
  top: 50px;
  color: #fff;
  font-size: 35px;
  &:hover {
    cursor: pointer;
  }
}
.photo_edit {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0px;

  .edit_pen {
    padding: 3px 5px;
    border-radius: 5px;
    background: #fff;
    opacity: 0;
    i {
      font-size: 16px;
      color: #55b9ff;
      font-weight: 600;
    }
  }
}
</style>
