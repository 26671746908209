<template>
  <div class="friend-list-tab mt-2">
    <tab-nav
      :pills="true"
      id="tab"
      class="nav nav-pills d-flex align-items-center justify-content-left friend-list-items p-0 mb-2"
    >
      <tab-nav-items :active="true" href="#global" :title="Global" />
      <tab-nav-items :active="false" href="#area" :title="Area" />
      <tab-nav-items :active="false" href="#national" :title="National" />
      <tab-nav-items :active="false" href="#regional" :title="Regional" />
      <tab-nav-items :active="false" href="#local" :title="Local" />
      <tab-nav-items :active="false" href="#other" :title="Other" />
    </tab-nav>
    <div class="tab-content">
      <tab-content-item :active="true" id="global">
        <Friend :friendData="global" :isHideIcon="isHideIcon" />
      </tab-content-item>
      <tab-content-item :active="false" id="area">
        <Friend :friendData="area" :isHideIcon="isHideIcon" />
      </tab-content-item>
      <tab-content-item :active="false" id="national">
        <Friend :friendData="national" :isHideIcon="isHideIcon" />
      </tab-content-item>
      <tab-content-item :active="false" id="regional">
        <Friend :friendData="region" :isHideIcon="isHideIcon" />
      </tab-content-item>
      <tab-content-item :active="false" id="local">
        <Friend :friendData="local" :isHideIcon="isHideIcon" />
      </tab-content-item>
      <tab-content-item :active="false" id="other">
        <Friend :friendData="other" :isHideIcon="isHideIcon" />
      </tab-content-item>
    </div>
  </div>
</template>
<script>
import { socialvue } from "@/config/pluginInit";
import Friend from "@/components/ComComponents/Friend";
export default {
  props: {
    area: {
      type: Array,
      default: [],
    },
    region: {
      type: Array,
      default: [],
    },
    local: {
      type: Array,
      default: [],
    },
    national: {
      type: Array,
      default: [],
    },
    global: {
      type: Array,
      default: [],
    },
    other: {
      type: Array,
      default: [],
    },
    isHideIcon: {
      type: Boolean,
      default: "",
    },
  },
  created() {},
  components: {
    Friend,
  },
  data() {
    return {};
  },
  computed: {
    Regional() {
      return "Regional" + "(" + this.region.length + ")";
    },
    Area() {
      return "Area" + "(" + this.area.length + ")";
    },
    Local() {
      return "Local" + "(" + this.local.length + ")";
    },
    National() {
      return "National" + "(" + this.national.length + ")";
    },
    Global() {
      return "Global" + "(" + this.global.length + ")";
    },
    Other() {
      return "Other" + "(" + this.other.length + ")";
    },
  },
  methods: {},
};
</script>
