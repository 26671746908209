<template>
  <section class="SkillsEndorsements">
    <div class="d-flex justify-content-between align-items-center">
      <h4>{{ $t("MyJCI.803@skills_sets") }}</h4>
      <span class="applyText" style="color:#50B5FF;font-weight: 500;font-size: 16px;cursor: pointer;" @click="openApplyCourse"
        v-if="isHideIcon">Apply as a Trainer</span>
    </div>
    <hr />

    <!-- Course -->
    <div class="course_container course_line" v-if="courseInfo.length">
      <h5>Completed Courses</h5>
      <section class="d-flex">
        <div class="item" v-for="(v, i) in courseInfo" :key="i" style="margin-top: 12px;">{{v.course}}</div>
      </section>
    </div>

    <!-- certificate -->
    <div class="course_container mb-5" v-if="certificateInfo.length">
      <h5>Passed Courses</h5>
      <section v-for="(v, i) in certificateInfo" :key="i" style="margin-top: 12px;">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex item align-items-center">
            <i class="ri-file-user-line mr-1" style="font-size: 16px;"></i>
            <span>{{v.course}}</span>
          </div>
          <span v-if="v.tenure_type" style="font-size: 24px;">∞</span>
          <span v-else>{{v.start_time}}~{{v.end_time}}</span>
        </div>
      </section>
    </div>

    <div class="empty" v-if="!(courseInfo.length||certificateInfo.length)"></div>

    <!-- 选择课程 -->
    <el-dialog title="Apply as a Trainer" :visible.sync="courseApply" width="50%">
      <section class="px-3" style="min-height: 120px;">
        <div class="course_select" v-for="(v, i) in courseInfo" :key="i">
          <div class="item skillTitle d-flex justify-content-between align-items-center mb-3">
            <span style="font-size: 16px;">{{v.course}}</span>
            <span v-if="v.is_certificate" style="color: #409EFF;">You are a trainer for this course.</span>
            <div class="apply_btn cursor-pointer" @click="openApply(v.course_id)" v-else>Apply as a Trainer</div>
          </div>
        </div>
      </section>
    </el-dialog>

    <!-- 讲师进度列表 -->
    <el-dialog title="Apply as a Trainer" :visible.sync="trainerApply" width="50%">
      <!-- Apply as Trainer -->
      <section v-if="is_next">
        <h5>Prerequisites</h5>
        <el-table :data="trainerList" style="width: 100%" tooltip-effect="light" :show-header="false"
          :row-style="handlerStyle" max-height="500px">
          <el-table-column prop="course" label="Course" show-overflow-tooltip></el-table-column>
          <el-table-column prop="condition" label="Condition" show-overflow-tooltip></el-table-column>
          <el-table-column prop="time" label="Time">
            <template slot-scope="scope">
              <span v-if="scope.row.time == 0">In the last 0 years</span>
              <span v-else>Within {{scope.row.time}} year</span>
            </template>
          </el-table-column>
          <el-table-column prop="is_meet" label="Time">
            <template slot-scope="scope">
              <span>{{scope.row.is_meet?'Effective':'Ineffective'}}</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="d-flex justify-content-end mt-5 next_apply_btn">
          <el-button type="info" @click="handlerPrevious">Previous</el-button>
          <el-button type="primary" :disabled="!disabled" @click="handlerNext">Next</el-button>
        </div>
      </section>

      <!-- Propose A Course -->
      <section class="propose_course" v-else>
        <el-form label-position="top" label-width="80px" :model="formLabelAlign">
          <el-form-item label="Name">
            <el-input v-model="formLabelAlign.trainer"></el-input>
          </el-form-item>
          <el-form-item label="National Organization">
            <el-select placeholder="Please select" v-model="organizationData" clearable value-key="id"
              style="width: 100%;">
              <el-option v-for="v in organizationList" :key="v.id" :label="v.name" :value="v"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Country">
            <el-select placeholder="Please select" v-model="countryData" clearable value-key="id" style="width: 100%;">
              <el-option v-for="v in countryList" :key="v.id" :label="v.name" :value="v"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Gender">
            <el-select v-model="formLabelAlign.gender" clearable style="width: 100%">
              <el-option label="Female" :value="0"></el-option>
              <el-option label="Male" :value="1"></el-option>
              <el-option label="Prefer not to say" :value="2"></el-option>
              <el-option label="Other" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Contact E-mail">
            <el-input v-model="formLabelAlign.contact_email"></el-input>
          </el-form-item>
          <el-form-item label="Phone">
            <el-input v-model="formLabelAlign.mobile"></el-input>
          </el-form-item>
          <el-form-item label="Training Language">
            <el-select v-model="formLabelAlign.training_language" multiple style="width: 100%;">
              <el-option v-for="(v,i) in languageList" :key="i" :label="v" :value="v">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Head Portrait">
            <el-upload class="avatar-uploader mr-3" action="no" :show-file-list="false" :http-request="uploadIMG"
              v-loading="loading">
              <img v-if="formLabelAlign.head_portrait" :src="formLabelAlign.head_portrait" alt="" />
              <img v-else src="@/assets/images/settings/camera.png" alt="" />
            </el-upload>
          </el-form-item>
        </el-form>
        <div class="d-flex justify-content-end mt-5 next_apply_btn">
          <el-button type="primary" :disabled="loading" @click="handlerSubmit">Submit</el-button>
        </div>
      </section>
    </el-dialog>

  </section>
</template>
<script>
  export default {
    name: "SkillsEndorsements",
    components: {},
    props: {
      isHideIcon: {
        type: Boolean,
        default: "",
      },
    },
    data() {
      return {
        courseInfo: [],
        certificateInfo: [],
        trainerApply: false,
        courseApply: false,
        disabled: false,
        formLabelAlign: {
          trainer: '',
          mobile: '',
          contact_email: '',
          head_portrait: '',
          gender: '',
          training_language: '',
        },
        is_next: true,
        trainerList: [],
        loading: false,
        languageList: [],

        countryList: [],
        organizationList: [],
        organizationData: { id: '', name: '' },
        countryData: { id: '', name: '' }
      };
    },
    computed: {},
    watch: {},
    methods: {
      // 获取国家列表
      async getAddress() {
        let { status, data } = await this.$http.getAddress({ level: 2 })
        if (status == 200) {
          this.countryList = data;
        }
      },

      // 获取组织列表
      async getOrganizationList() {
        let res = await this.$http.getOrganizationList({ type: 2 });
        if (res.status == 200) {
          this.organizationList = res.data;
        }
      },

      // 获取培训详情
      async getCompletedCourse() {
        let userInfo = this.$getlocalStorage("userInfo");
        let selfId = userInfo ? JSON.parse(userInfo).user_id : 0;
        let router_id = parseInt(this.$route.query.user_id);
        let id = "";
        if (router_id) {
          id = router_id;
        } else {
          id = selfId;
        }
        let res = await this.$http.getCompletedCourse({ user_id: id })
        if (res.status == 200) {
          this.courseInfo = res.data.completed_course
          this.certificateInfo = res.data.certificate
        }
      },

      // 上传图片
      uploadIMG(params) {
        var formData = new FormData();
        formData.append("file", params.file);
        this.loading = true
        this.$http.uploadImage(formData).then((res) => {
          if (res.status == 200) {
            this.formLabelAlign.head_portrait = res.data[0];
          }
          setTimeout(() => { this.loading = false }, 500);
        });
      },

      openApplyCourse() {
        this.courseApply = true
        // this.getCompletedCourse()
      },

      openApply(course_id) {
        this.courseApply = false
        this.trainerApply = true
        this.course_id = course_id
        this.checkApplyTrainer()
        this.is_next = true
      },

      handlerPrevious() {
        this.trainerApply = false
        this.courseApply = true
        this.course_id = ''
      },

      handlerNext() {
        this.getAddress()
        this.getOrganizationList()
        this.is_next = false
        this.getTrainerInfo()
      },

      // 查看成为讲师进度
      checkApplyTrainer() {
        this.$http.checkApplyTrainer({ course_id: this.course_id }).then((res) => {
          if (res.status == 200) {
            this.trainerList = res.data;
            this.disabled = this.trainerList.every(v => v.is_meet == 1)
          }
        });
      },

      // 成为讲师
      handlerSubmit() {
        this.formLabelAlign.organization = this.organizationData.name
        this.formLabelAlign.organization_id = this.organizationData.id
        this.formLabelAlign.country_id = this.countryData.id
        this.formLabelAlign.country = this.countryData.name
        this.$http.applyTrainer({ ...this.formLabelAlign, course_id: this.course_id }).then((res) => {
          if (res.status == 200) {
            this.trainerApply = false
            this.getCompletedCourse()
          }
        });
      },

      // 讲师信息
      getTrainerInfo() {
        this.$http.getTrainerInfo({ course_id: this.course_id }).then((res) => {
          if (res.status == 200) {
            Object.keys(this.formLabelAlign).forEach((v) => {
              this.formLabelAlign[v] = res.data[v]
            });
            this.countryData = { id: res.data.country_id, name: res.data.country }
            this.organizationData = { id: res.data.organization_id, name: res.data.organization }
            this.languageList = res.data.course_language
          }
        });
      },

      handlerStyle({ row, rowIndex }) {
        if (row.is_meet == 1) {
          return {
            backgroundColor: '#ecf5ff',
            color: '#50B5FF',
            border: '1px solid #50b5ff'
          }
        }
      },

    },
  };
</script>
<style src='' lang='scss' scoped>
  @media screen and (min-width: 992px) {
    ::v-deep #addSkill .modal-dialog {
      max-width: 800px !important;
    }

    ::v-deep #editSkill .modal-dialog {
      max-width: 800px !important;
    }
  }
  @media (max-width: 600px) {
    ::v-deep .el-dialog{
      width: 95% !important;
    }
    h4{
      font-size: 1em !important;
    }
    .applyText{
      font-size: 12px !important;
    }
  }
  .empty {
    display: flex;
    justify-content: center;
    margin: 80px 0;
  }

  .course_container {
    color: #ffba68;
    font-size: 16px;

    .item {
      background: #fffcf3;
      border: 1px solid #ffba68;
      border-radius: 5px;
      padding: 3px 8px;
      font-size: 14px;
      margin-right: 10px;
    }
    
  }

  .course_select {
    .skillTitle {
      span:first-child{
        width: 40%;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      span:last-child{
        width: 60%;
        text-align: right;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .apply_btn {
      border: 1px solid #ffba68;
      color: #ffba68;
      padding: 3px 8px;
    }
  }

  .course_line {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #eee;
  }

  /deep/ .el-table .cell {
    white-space: nowrap !important;
  }

  /deep/ .el-table::before {
    width: 0;
  }

  /deep/ .el-table__header-wrapper {
    margin-bottom: 10px;
  }

  /deep/ .el-table tr {
    background: #eee;
    margin-bottom: 10px;
  }

  /deep/ .el-table tbody tr td {
    padding-left: 30px !important;
  }

  /deep/ .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border: none;
  }

  /deep/ .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border: none;
  }

  /deep/ .el-table__body {
    border-collapse: separate;
    border-spacing: 0 10px;
    table-layout: auto !important;
  }

  /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
    background-color: transparent !important;
  }

  .propose_course {
    /deep/ .el-form-item__label {
      line-height: 20px;
    }

    /deep/ .el-form--label-top .el-form-item__label {
      padding: 0
    }

    .avatar-uploader {
      width: 90px;
      height: 90px;
      overflow: hidden;

      img {
        width: 90px;
        height: 90px;
      }
    }
  }
</style>