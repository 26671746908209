<template>
  <b-row>
    <b-col lg="4">
      <iq-card>
        <template v-slot:headerTitle>
          <div class="d-flex justify-content-between cardTitle">
            <h4 class="card-title">Albums</h4>
            <p class="m-0 text-center pb-2 self-pointer text-primary" @click="$emit('toMedia')">
              view all
            </p>
          </div>
        </template>
        <template v-slot:body>
          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
            <li class="col-md-4 col-4 pl-2 pr-0 pb-2" v-for="(album, index) in photoList" :key="index">
              <a href="#">
                <el-image class="img-fluid" v-if="album.path" :src="album.path" @click="handleClickPopup(album.path)"
                  fit="cover">
                  <div slot="error" class="image-slot">
                    <img class="img-fluid" src="./Media/components/default.png" />
                  </div>
                </el-image>
                <!-- <img
                  v-if="album.photo_path"
                  :src="album.photo_path"
                  class="img-fluid"
                />
                <img
                  v-else
                  src="./Media/components/default.png"
                  class="img-fluid"
                  alt=""
                /> -->
              </a>
            </li>
          </ul>
        </template>
      </iq-card>
      <iq-card>
        <template v-slot:headerTitle>
          <div class="d-flex justify-content-between cardTitle">
            <h4 class="card-title">Friends</h4>
            <p class="m-0 text-center pb-2 self-pointer text-primary" @click="$emit('toAllFriend')">
              view all
            </p>
          </div>
        </template>
        <template v-slot:body>
          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
            <li class="col-md-4 col-4 pl-2 pr-0 pb-3" v-for="(friend, index) in friendsList" :key="index">
              <router-link :to="{ path: '/profile?user_id=' + friend.user_id }" @click.native="refresh">
                <img :src="friend.avatar" class="img-fluid" />
              </router-link>
              <h6 class="mt-2" style="overflow: hidden; text-overflow: ellipsis">
                {{ friend.nick_name }}
              </h6>
            </li>
          </ul>
        </template>
        <!-- <template>
          <p
            class="m-0 text-center pb-2 self-pointer text-primary"
            @click="$emit('toAllFriend')"
          >
            See All Friends
          </p>
        </template> -->
      </iq-card>
    </b-col>
    <b-col lg="8">
      <div id="post-modal-data" class="iq-card" style="box-shadow: none">
        <!-- 如果不是自己的个人中心隐藏发帖 -->
        <AddSocialPost :isShowAddPostModal="isHideIcon" @addPost="addPost" :friendList="friendsList"
          :noShowPermission="true"></AddSocialPost>

        <div v-for="post in userPostList" :key="post.id">
          <!-- <SocialPost :post="post" pageType="home" @refreshPostList="fresh" @SharePostDetail="getSharePostDetStatus"
                                :friendList="friendsList" :isPostType="true" @editPost="getPostInfo"></SocialPost> -->
          <SocialPost :post="post" pageType="home" @SharePostDetail="getSharePostDetStatus" :friendList="friendsList"
            :isPostType="true" @editPost="getPostInfo"></SocialPost>
        </div>
        <div v-if="postLoading">
          <el-skeleton animated :count="2">
            <template slot="template">
              <iq-card body-class="p-0">
                <div style="padding: 14px;" class="d-flex align-items-center">
                  <el-skeleton-item variant="circle" style="width: 60px; height: 60px;" />
                  <div class="ml-3 d-flex flex-column">
                    <el-skeleton-item variant="text" style="width: 120px;" />
                    <el-skeleton-item variant="text" style="width: 80px;margin-top: 10px;" />
                  </div>
                </div>
                <div style="padding: 20px;" class="d-flex justify-content-between mt-5">
                  <el-skeleton-item variant="text" style="width: 60px;" />
                  <el-skeleton-item variant="text" style="width: 60px;" />
                  <el-skeleton-item variant="text" style="width: 60px;" />
                </div>
              </iq-card>
            </template>
          </el-skeleton>
        </div>
      </div>
      <p v-if="noMore&&userPostList.length" class="no_more_common">There's no more content</p>
    </b-col>
    <div class="popup" v-if="popupIsShow">
      <div class="imgflex">
        <img class="img" :src="src" alt="" />
        <img src="../../../assets/images/public/close.png" @click="closeImg" alt="" class="iconImg" />
      </div>
    </div>
  </b-row>
</template>

<script>
  import AddSocialPost from "@/components/ComComponents/ComPost/AddSocialPost";
  import SocialPost from "@/components/ComComponents/ComPost/SocialPost1";
  export default {
    components: { AddSocialPost, SocialPost },
    props: ["isHideIcon", "photoList"],
    data() {
      return {
        friendsList: [],
        userPostList: [],
        popupIsShow: false,
        user_id: "",
        friends: [],
        isHideAddPost: false,
        page: 1,
        size: 10,
        postLoading: false,
        noMore: false
        // totalNum: 0,
      };
    },
    created() {
      this.userFriendList();
      this.getUserPostList();
      this.$bus.$on("updateFriend", () => {
        this.userFriendList();
      });
      this.$bus.$on('refreshPost', () => {
        this.userPostList = []
        this.page = 1
        this.getUserPostList()
      })
    },

    mounted() {
      this.$nextTick(() => {
        this.$on('funInfinitePost', () => {
          this.page++
          this.getUserPostList()
        })
      })
    },

    methods: {
      handleClickPopup(licence) {
        this.popupIsShow = true;
        this.src = licence;
      },
      closeImg() {
        // console.log(1111);
        this.popupIsShow = false;
        this.src = "";
      },
      refresh() {
        // this.$router.go(0)
        location.reload();
      },
      getAllPhoto() {
        let routerId = this.$route.query.user_id;
        let userId = JSON.parse(localStorage.getItem("userInfo")).id;
        let photoId = routerId ? routerId : userId;
        this.$http
          .getAllPhoto({
            model_type: 1,
            model_id: photoId,
            per_page: 9,
          })
          .then((res) => {
            if (res.status == 200) {
              this.photoList = res.data.data;
              // console.log('allPhoto', res)
            }
          });
      },
      // permision--好友列表
      userFriendList() {
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.$http
          .userFriendList({
            user_id: this.$route.query.user_id
              ? this.$route.query.user_id
              : userInfo.user_id,
            type: 4,
          })
          .then((res) => {
            let list = res.data;
            this.friendsList = list.length > 9 ? list.splice(0, 9) : list;
          });
      },
      // 获取帖子列表---没有id就拿自己的id
      getUserPostList() {
        this.postLoading = true
        let selfId = JSON.parse(this.$getlocalStorage("userInfo")).user_id;
        let router_id = this.$route.query.user_id;
        this.isHideAddPost = selfId == router_id;
        this.$http
          .getUserPostList({
            user_id: router_id || selfId,
            page: this.page,
            size: this.size,
          })
          .then((res) => {
            if (res.status == 200) {
              // this.userPostList = res.data.data;
              this.userPostList = [...this.userPostList, ...res.data.data]
              // this.totalNum = res.data.total;
            }

            if (res.data.current_page == res.data.last_page) {
              this.$emit('fatherMethod');
              this.noMore = true
            }
            setTimeout(() => {
              this.postLoading = false
            }, 50);
          });
      },
      // 发帖
      addPost() {
        let [post, permission, shareData] = arguments;
        let postData = {
          id: "",
          type_id: "",
          content: "",
          media: {},
          friends: [],
          location: "",
          longitude: "",
          latitude: "",
          share_type: null,
          share_id: null,
        };
        Object.keys(postData).forEach((key) => {
          postData[key] = post[key] || postData[key];
        });
        this.$http
          .createPost({
            type: 1, // 个人发帖
            permission: permission,
            ...postData,
          })
          .then((res) => {
            if (res.status == 200) {
              if (post.share_id) {
                shareData.share_total++
              } else {
                this.page = 1
                this.userPostList = []
                this.getUserPostList();
              }
            }
          });
      },

      // 评论-点赞-删除的重新获取帖子列表
      fresh(val) {
        if (val) {
          this.getUserPostList();
        }
      },

      getSharePostDetStatus(post) {
        this.$router.push({
          path: `/newsfeeds/${post.info_url}`,
        });
      },

      changePage(page) {
        this.page = page;
        this.getUserPostList();
      },
      getPostInfo(v) {
        // console.log("postInfo1111111111", v);
      },
    },
    beforeDestroy() {
      this.$bus.$off("updateFriend");
      this.$bus.$off("refreshPost")
    },
  };
</script>
<style lang="scss" scoped>
  .img-fluid {
    width: 90px;
    height: 90px;
    object-fit: cover;
  }

  .popup {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    outline: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1024;
    display: flex;
    justify-content: center;
    align-items: center;

    .imgflex {
      position: relative;
      top: 60px;

      .img {
        width: 560px;
        max-height: 850px;
      }

      .iconImg {
        position: absolute;
        width: 30px;
        height: 30px;
        top: -5px;
        right: -5px;
      }
    }
  }
  @media (min-width: 990px) {
    .cardTitle{
      width: 270px;
    }
  }
  @media (max-width: 990px) {
    .cardTitle{
      width: 600px;
    }
  }
  @media (max-width: 768px) {
    .cardTitle{
      width: 470px;
    }
  }
  @media (max-width: 578px) {
    .cardTitle{
      width: 315px;
    }
  }
</style>