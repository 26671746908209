<template>
  <div>
    <b-modal id="editPersonalInformation" centered :title="$t('MyJCI.30d@edit_personal_inform')" hide-footer>
      <section>
        <div class="img_box">
          <div style="">
            <img style="" ref="imgAva" :src="form.avatar" alt="" />
          </div>
          <div class="edit-pen">
            <el-upload class="avatar-uploader" action="no" :show-file-list="false" :http-request="uploadAvatar">
              <i class="las la-pen" style="color:#eee"></i>
            </el-upload>
          </div>
        </div>
        <div class="form-group">
          <label for="Nickname">{{$t('MyJCI.27d@nick_name')}}</label>
          <input type="text" v-model="form.nick_name" class="form-control" id="Nickname" />
        </div>
        <div class="form-group">
          <!-- <label for="Motto">{{$t('MyJCI.45c@motto')}}</label> -->
          <label for="Motto">Bio</label>
          <input type="text" v-model="form.motto" class="form-control" id="Motto" />
        </div>
        <b-button variant="primary" type="submit" @click="editUser" class="mt-3 float-right mb-2">
          {{$t('MyJCI.7d3@confirm')}}</b-button>
        <b-button variant="secondary" type="reset" @click="$bvModal.hide('editPersonalInformation')"
          class="mt-3 float-right mr-2">{{$t('MyJCI.d99@cancel')}}</b-button>
      </section>
    </b-modal>
    <b-modal id="picModal" :title="$t('MyJCI.383@select_image_focus')" hide-footer>
      <div style="width: 300; height: 300px">
        <vueCropper ref="cropper" :img="option.img" :outputSize="1" :autoCrop="true" :canScale="true"
          :canMoveBox="false" :fixedNumber="[1, 1]" :fixedBox="true" canMove fixed></vueCropper>
      </div>
      <div class="d-flex justify-content-end mt-2">
        <b-button class="mr-2 btnOrange" @click="$bvModal.hide('picModal')">
          {{$t('MyJCI.d99@cancel')}}</b-button>
        <b-button variant="primary" @click="catAvatar">{{$t('MyJCI.7d3@confirm')}}</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import { mapActions } from "vuex";

  export default {
    props: {
      list: {
        type: Object,
        default: {},
      },
    },
    data() {
      return {
        option: {
          img: "",
        },
        form: {
          nick_name: "",
          motto: "",
          avatar: "",
        },
      };
    },
    components: {},
    mounted() {
      this.$root.$on("bv::modal::hide", () => {
        this.option.img = "";
      });
    },
    methods: {
      asset() {
        this.form.nick_name = this.list.nick_name;
        this.form.motto = this.list.motto;
        this.form.avatar = this.list.avatar;
      },
      ...mapActions({
        getUserInfo: "Public/getUserInfo",
      }),
      // 上传头像
      uploadAvatar(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file.file);
        let that = this;
        reader.onload = function (e) {
          that.option.img = this.result;
          that.$bvModal.show("picModal");
        };
      },
      catAvatar() {
        this.$refs.cropper.getCropBlob(async (blobFile) => {
          let formData = new FormData();
          let fileOfBlob = new File([blobFile], getName() + ".jpg");
          formData.append("file", fileOfBlob);
          let { data } = await this.$http.uploadImage(formData);
          this.form.avatar = data[0];
          this.$bvModal.hide("picModal");
          // 生成唯一标识
          function getName() {
            return +(
              parseInt(new Date().getTime() / 1000) +
              "" +
              Math.round(Math.random() * (9999 - 1000) + 1000)
            );
          }
        });
      },
      // 编辑提交信息
      async editUser() {
        // this.list.avatar = this.avatar;
        let { status } = await this.$http.editUser({ ...this.form });
        if (status == 200) {
          this.$bvModal.hide("editPersonalInformation");
          this.list.avatar = this.form.avatar;
          this.list.motto = this.form.motto;
          this.list.nick_name = this.form.nick_name;
          this.$emit("getNewUserInfo")
          this.getUserInfo()
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  @media screen and (min-width: 992px) {
    ::v-deep #editPersonalInformation .modal-dialog {
      max-width: 800px !important;
    }
  }

  .opacity {
    opacity: 0;
  }

  #editPersonalInformation {
    .img_box {
      width: 150px;
      height: 150px;
      margin: 0 auto;
      position: relative;

      >div {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        overflow: hidden;

        >img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      >.edit-pen {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        z-index: 9999;
        text-align: center;
        line-height: 30px;
        background-color: #50b5ff;
        color: #fff;
        position: absolute;
        bottom: 17px;
        left: 118px;

        .el-upload {
          position: relative;

          i {
            position: absolute;
            top: 3px;
            left: 5px;
          }
        }
      }
    }
  }

  .edit-profile-avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .edit-icon {
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    color: #50b5ff;
  }

  .profile-info {
    li {
      display: flex;
      margin-bottom: 10px;

      h5 {
        width: 30%;
        color: #333;
      }

      p {
        color: #666;
        margin-bottom: 0;

        i {
          font-size: 12px;
          line-height: 30px;
        }
      }
    }
  }
</style>