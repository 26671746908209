<template>
  <section class="apply_career">
    <div class="d-flex justify-content-between align-items-center">
      <h4>JCI Career</h4>
      <div class="mb-0 d-flex" v-if="isHideIcon">
        <div class="btn_log" @click="openCareerLog">Position History</div>
        <div class="btn_apply" @click="openRequest">+Apply</div>
        <!-- <button type="button" style="color: #50b5ff" class="btn" @click="openRequest()">
          <i class="ri-edit-line"></i>
          <span>{{ $t("MyJCI.3b1@edit") }}</span>
        </button> -->
      </div>
    </div>
    <hr />
    <!-- <ul class="suggestions-lists m-0 p-0 d-flex">
      <li
        class="d-flex mb-4 align-items-center position-relative"
        v-if="isHideIcon"
      >
        <button
          type="button"
          class="w-100 btn h-100 position-absolute left-0 top-0 opacity"
          @click="openRequest()"
        ></button>
        <div class="user-img img-fluid">
          <i class="ri-add-fill"></i>
        </div>
        <div class="media-support-info ml-3">
          <h6>{{ $t("MyJCI.abe@add_position") }}</h6>
        </div>
      </li>
    </ul> -->

    <!-- <ul class="career-list p-0" v-if="userCareerList.no_limit.length">
      <li v-for="(item, index) in userCareerList" :key="index">
        <span style="width:155px"> {{ item.time|filterTime }} </span>
        <p class="p-2">{{ item.job_title }}</p>
      </li>
    </ul> -->

    <!-- -----用户职称简单版----- -->
    <section
      v-if="userCareerList&&(userCareerList.no_limit&&userCareerList.no_limit.length||userCareerList.limit&&userCareerList.limit.length)">
      <div class="no_limit" v-if="userCareerList.no_limit.length">
        <h6>{{userCareerList.no_limit[0].current_year}}</h6>
        <div class="d-flex align-items-center flex-1 flex-wrap">
          <div class="career ml-3" v-for="(v, i) in userCareerList.no_limit" :key="i">
            <p class="org" :title="v.organization">{{v.organization}}</p>
            <p class="tit" :title="v.job_title_name">{{v.job_title_name}}</p>
          </div>
        </div>
      </div>

      <div class="no_limit" v-if="userCareerList.limit&&userCareerList.limit.length">
        <div class="mb-3 no_limit_bottom" v-for="(val, ind) in userCareerList.limit" :key="ind">
          <h6>{{val[0].start_time}} - {{val[0].end_time}}</h6>
          <div class="d-flex align-items-center flex-1 flex-wrap">
            <div class="career ml-3" v-for="(v, i) in val" :key="i">
              <p class="org" :title="v.organization">{{v.organization}}</p>
              <p class="tit" :title="v.job_title_name">{{v.job_title_name}}</p>
            </div>
          </div>
        </div>
      </div>

    </section>
    <div class="empty" v-else>
      <!-- ——Empty—— -->
    </div>


    <!-- 职位弹框 -->
    <b-modal id="Career" centered :title="$t('MyJCI.007@jci_career')" hide-footer>
      <!-- 等级 -->
      <div class="mb-3">
        <label>{{ $t("MyJCI.227@level") }} <span style="color: red">*</span>
        </label>
        <br />
        <el-select v-model="level" class="w-100" @change="job_title_id='',getJobTitle()" filterable
          :placeholder="$t('MyJCI.c16@please_select')" clearable>
          <el-option v-for="item in levelList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </div>

      <div class="mb-3">
        <label>JCI Career <span style="color: red">*</span>
        </label>
        <br />
        <el-select class="w-100" placeholder="Please select" v-model="job_title_id" clearable>
          <el-option v-for="item in careerList" :key="item.job_title_id" :label="item.job_title_name"
            :value="item.job_title_id">
          </el-option>
        </el-select>
      </div>

      <!-- <div class="mb-3">
        <label>Limitless</label>
        <br />
        <el-switch v-model="tenure_type" :active-value="1" :inactive-value="0"></el-switch>
      </div> -->

      <!-- 年份 -->
      <div class="mb-3" v-if="tenure_type==0">
        <label>Period of Responsibility</label>
        <div class="row">
          <div class="col">
            <el-date-picker v-model="start_time" class="w-100" value-format="yyyy-MM-dd" type="date"
              placeholder="Start Date" :picker-options="pickerStartOptions" @focus="limitStartDate">
            </el-date-picker>
          </div>
          <div class="col">
            <el-date-picker v-model="end_time" class="w-100" value-format="yyyy-MM-dd" type="date"
              placeholder="End Date" :picker-options="pickerEndOptions" @focus="limitEndDate">
            </el-date-picker>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label>Introduction</label>
        <br />
        <el-input v-model="remark" type="textarea" :rows="6" placeholder="Please enter the content" resize="none"
          clearable>
        </el-input>
      </div>

      <button type="button" class="btn btn-primary mt-2 float-right" @click="addUserJobTitle">
        {{ $t("MyJCI.f2c@submit") }}
      </button>

      <!-- <div class="mt-2">
        <label v-if="this.level == 0">JCI</label>
        <label v-if="this.level == 1">{{ $t("MyJCI.f80@area") }}</label>
        <label v-if="this.level == 2 || this.level == 3 || this.level == 4">{{
          $t("MyJCI.f16@national")
          }}</label>
        <br />
        <el-select v-model="organ" value-key="id" class="w-100" filterable :placeholder="$t('MyJCI.c16@please_select')"
          @change="getlocalList(organ, 1)">
          <el-option v-for="item in organList" :key="item.id" :label="item.name" :value="item">
          </el-option>
        </el-select>
      </div> -->

      <!-- <div class="mt-2" v-if="this.level == 3 || this.level == 4">
        <label>{{ $t("MyJCI.678@regional") }}</label>
        <br />
        <el-select v-if="regionalList.length > 0" value-key="id" v-model="regional" @change="getlocalList(regional, 0)"
          class="w-100" filterable :placeholder="$t('MyJCI.c16@please_select')">
          <el-option v-for="item in regionalList" :key="item.id" :label="item.name" :value="item">
          </el-option>
        </el-select>
        <el-select v-else v-model="regional" class="w-100" disabled :placeholder="$t('MyJCI.012@there_are_no_provinc')">
          <el-option value=""></el-option>
        </el-select>
      </div> -->

      <!-- <div v-if="this.level == 4">
        <label>{{ $t("MyJCI.f30@local") }}</label>
        <br />
        <el-select v-if="localList.length > 0" v-model="local" class="w-100" filterable
          :placeholder="$t('MyJCI.c16@please_select')">
          <el-option v-for="item in localList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <el-select v-else v-model="local" class="w-100" disabled :placeholder="$t('MyJCI.ea3@there_is_no_local_in')">
          <el-option value=""></el-option>
        </el-select>
      </div> -->

      <!-- careerType -->
      <!-- <div class="pt-2">
        <label>{{ $t("MyJCI.d28@career_type") }}
          <span style="color: red">*</span></label>
        <br />
        <el-select class="w-100" v-model="careerType" filterable :placeholder="$t('MyJCI.c16@please_select')"
          @change="changeType(careerType)">
          <el-option v-for="item in carTypeList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </div> -->

      <!-- <div class="pt-2" v-if="this.careerType == 2">
        <label>{{ $t("MyJCI.5e6@projects") }}</label>
        <br />
        <el-select class="w-100" v-model="project" filterable :placeholder="$t('MyJCI.c16@please_select')"
          @change="titleList">
          <el-option v-for="item in projList" :key="item.id" :label="item.title" :value="item.id">
          </el-option>
        </el-select>
      </div> -->

      <!-- 职称 -->
      <!-- <div class="pt-2">
        <label>{{ $t("MyJCI.2c7@position") }} <span style="color: red">*</span>
        </label>
        <br />
        <el-select class="w-100" v-if="carList.length > 0" v-model="career" value-key="id" filterable
          :placeholder="$t('MyJCI.c16@please_select')">
          <el-option v-for="item in carList" :key="item.id" :label="item.name" :value="item">
          </el-option>
        </el-select>
        <el-select v-else v-model="career" class="w-100" disabled :placeholder="$t('MyJCI.6e8@there_is_no_position')">
          <el-option value=""></el-option>
        </el-select>
      </div> -->

      <!-- 添加 -->
      <!-- <div class="text-right mt-3">
        <button class="btn" style="color: #50b5ff; border: 1px solid #50b5ff" @click="applyJobTitle()">
          {{ $t("MyJCI.d69@add") }}
        </button>
      </div>
      <div class="row mt-2" v-for="(v, i) of applyList" :key="i">
        <div class="col-5" v-if="v.end_year">
          {{ v.start_year }}-{{ v.end_year }}
        </div>
        <div class="col-5" v-if="!v.end_year">{{ v.start_year }}</div>
        <div class="col-2">{{ v.organization_name }}</div>
        <div class="col-2">{{ v.type_text }}</div>
        <div class="col-2">{{ v.job_title }}</div>
        <div class="col-1">
          <i class="ri-delete-bin-6-line" @click="confirmDel(v.id)"></i>
        </div>
      </div>
      <hr /> -->
    </b-modal>

    <!-- 申请记录弹框 -->
    <b-modal id="CareerLog" centered title="JCI Career History" hide-footer>
      <section class="log_container">
        <el-table :data="tableData" style="width: 100%" tooltip-effect="light" :row-class-name="tableRowClassName"
          max-height="560">
          <el-table-column prop="level" label="Level"></el-table-column>
          <el-table-column prop="job_title_name" label="JCI Career" show-overflow-tooltip></el-table-column>
          <el-table-column label="Period of Responsibility" show-overflow-tooltip min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.tenure_type">Term has no limit</span>
              <span v-else>{{scope.row.start_time}}-{{scope.row.end_time}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="created_at" label="Application Date" show-overflow-tooltip min-width="100">
          </el-table-column>
          <el-table-column label="Status" width="90">
            <template slot-scope="scope">
              <span>{{{1:'Active',2:'Inactive',0:'Inactive'}[scope.row.is_apply]}}</span>
            </template>
          </el-table-column>
        </el-table>
      </section>
    </b-modal>
  </section>
</template>

<script>
  export default {
    props: {
      // userCareerList: {
      //   type: Array,
      //   default: [],
      // },
      isHideIcon: {
        type: Boolean,
        default: "",
      },
    },
    data() {
      return {
        levelList: [
          { id: 1, name: "JCI" },
          { id: 2, name: "Area" },
          { id: 3, name: "National" },
          { id: 4, name: "Regional" },
          { id: 5, name: "Local" },
        ],
        //级别列表
        // carTypeList: [
        //   { id: 0, name: "BoD" },
        //   { id: 1, name: "Committee" },
        //   { id: 2, name: "Projects" },
        //   { id: 3, name: "Staff" },
        //   { id: 4, name: "Other" },
        // ],
        // carList: [], //职称列表
        // applyList: [], //职称申请列表
        // organList: [],
        // beginYear: "", //选中开始年份
        // endYear: "", //选中的结束的年份
        // careerType: "",
        // regional: {},
        // regionalList: [],
        // local: "",
        // localList: [],
        // career: {}, //职称下拉选
        // organ: {}, //选中的组织
        // projList: [],
        // project: "",

        level: "", //选中的等级
        job_title_id: '',
        userCareerList: [],
        careerList: [],
        tenure_type: 0,
        start_time: '',
        end_time: '',
        remark: '',
        pickerStartOptions: {},
        pickerEndOptions: {},
        tableData: []
      };
    },
    filters: {
      // 筛选地区名字
      // filterTime(val) {
      //   return val.split(" ")[0];
      // }
    },
    components: {},
    created() {
      let userInfo = this.$getlocalStorage("userInfo");
      let selfId = userInfo ? JSON.parse(userInfo).user_id : 0;
      let router_id = parseInt(this.$route.query.user_id);
      this.id = "";
      if (router_id) {
        this.id = router_id;
      } else {
        this.id = selfId;
      }
    },
    methods: {
      // 获取用户职称简单版
      async getUserJobTitleSingle() {
        let res = await this.$http.getUserJobTitleSingle({ user_id: this.id })
        if (res.status == 200) {
          this.userCareerList = res.data
        }
      },

      // 筛选职称
      async getJobTitle(val) {
        let res = await this.$http.getJobTitle({
          level: this.level,
        })
        if (res.status == 200) {
          this.careerList = res.data
        }
      },

      // 打开申请弹框
      openRequest() {
        this.job_title_id = this.level = this.start_time = this.end_time = this.remark = ''
        this.tenure_type = 0
        this.getJobTitle()
        this.$bvModal.show("Career");
      },

      // 打开记录弹框
      openCareerLog() {
        this._getUserJobTitleLog()
        this.$bvModal.show("CareerLog");
      },

      // 获取申请记录
      async _getUserJobTitleLog() {
        let res = await this.$http.getUserJobTitleLog({ user_id: this.id })
        if (res.status) {
          this.tableData = res.data
        }
      },

      // 申请添加职称
      async addUserJobTitle() {
        if (!this.level) return this.$message.warning('Please select level.')
        if (!this.job_title_id) return this.$message.warning('Please select JCI career.')
        this.title = ""
        let data = {
          job_title_id: this.job_title_id,
          tenure_type: this.tenure_type,
          start_time: this.start_time,
          end_time: this.end_time,
          level: this.level,
          remark: this.remark
        }
        let res = await this.$http.addUserJobTitle(data)
        if (res.status == 200) {
          this.getUserJobTitleSingle()
          this.$bvModal.hide('Career')
        }
      },

      limitStartDate() {
        let _this = this
        this.pickerStartOptions.disabledDate = function (time) {
          return time.getTime() > new Date(_this.end_time).getTime()
        };
      },

      limitEndDate() {
        let _this = this
        this.pickerEndOptions.disabledDate = function (time) {
          return time.getTime() < new Date(_this.start_time).getTime()
        };
      },

      // 表格样式
      tableRowClassName({ row, rowIndex }) {
        if (row.is_apply == 2) {
          return 'warning-row';
        } else {
          return 'success-row';
        }
      }

      // 选中career type
      // changeType(careerType) {
      //   this.career = "";
      //   if (careerType == 2) {
      //     this.projectList();
      //   } else {
      //     this.titleList();
      //   }
      // },

      // 删除时确认
      // confirmDel(id) {
      //   this.$confirm("Are you sure you want to delete?", "Tips", {
      //     distinguishCancelAndClose: true,
      //     confirmButtonText: "Confirm",
      //     cancelButtonText: "Cancel",
      //     type: "warning",
      //   })
      //     .then(() => {
      //       this.titleDel(id);
      //     })
      //     .catch(() => {
      //       this.$message({
      //         type: "info",
      //         message: this.$t('MyJCI.1e9@deletion_cancelled'),
      //       });
      //     });
      // },

      // 获取组织列表
      // getOrganizationList(type) {
      //   this.$http.getOrganizationList({ type }).then((res) => {
      //     if (res.status == 200) {
      //       this.organList = res.data;
      //     }
      //   });
      // },

      // 获取本地组织
      // getlocalList(parent, type) {
      //   // console.log(parent);
      //   this.$http.getOrganizationList({ parent_id: parent.id }).then((res) => {
      //     if (res.status == 200) {
      //       if (type == 1 && parent.has_regions == 1) {
      //         this.regionalList = res.data;
      //       } else {
      //         this.localList = res.data;
      //       }
      //     }
      //   });
      // },

      // 选中职称等级
      // allPlease(level) {
      //   this.areaList = [];
      //   this.nationalList = [];
      //   this.localList = [];
      //   this.organ = "";
      //   this.regionalList = [];
      //   this.carList = [];
      //   if (level == 0) {
      //     this.getOrganizationList(0);
      //   } else if (level == 1) {
      //     this.getOrganizationList(4);
      //   } else if (level == 2) {
      //     this.getOrganizationList(2);
      //   } else if (level == 3) {
      //     this.getOrganizationList(2);
      //   } else if (level == 4) {
      //     this.getOrganizationList(2);
      //   }
      // },

      // 开始年份
      // yearStart(dateTime) {
      //   this.beginYear =
      //     dateTime.getFullYear() +
      //     "-" +
      //     (dateTime.getMonth() + 1 < 10
      //       ? "0" + (dateTime.getMonth() + 1)
      //       : dateTime.getMonth() + 1) +
      //     "-" +
      //     (dateTime.getDate() < 10
      //       ? "0" + dateTime.getDate()
      //       : dateTime.getDate()) +
      //     " " +
      //     (dateTime.getHours() < 10
      //       ? "0" + dateTime.getHours()
      //       : dateTime.getHours()) +
      //     ":" +
      //     (dateTime.getMinutes() < 10
      //       ? "0" + dateTime.getMinutes()
      //       : dateTime.getMinutes()) +
      //     ":" +
      //     (dateTime.getSeconds() < 10
      //       ? "0" + dateTime.getSeconds()
      //       : dateTime.getSeconds());
      //   // console.log(this.beginYear);
      // },

      // 结束年份
      // yearEnd(dateTime) {
      //   this.endYear =
      //     dateTime.getFullYear() +
      //     "-" +
      //     (dateTime.getMonth() + 1 < 10
      //       ? "0" + (dateTime.getMonth() + 1)
      //       : dateTime.getMonth() + 1) +
      //     "-" +
      //     (dateTime.getDate() < 10
      //       ? "0" + dateTime.getDate()
      //       : dateTime.getDate()) +
      //     " " +
      //     (dateTime.getHours() < 10
      //       ? "0" + dateTime.getHours()
      //       : dateTime.getHours()) +
      //     ":" +
      //     (dateTime.getMinutes() < 10
      //       ? "0" + dateTime.getMinutes()
      //       : dateTime.getMinutes()) +
      //     ":" +
      //     (dateTime.getSeconds() < 10
      //       ? "0" + dateTime.getSeconds()
      //       : dateTime.getSeconds());
      // },

      // 项目列表
      // projectList() {
      //   if (this.local) {
      //     let id = this.local;
      //     this.$http
      //       .projectList({ per_page: 999999, organization_id: id })
      //       .then((res) => {
      //         if (res.status == 200) {
      //           // console.log(res.data.data);
      //           this.projList = res.data.data;
      //         }
      //       });
      //   } else if (this.regional) {
      //     let id = this.regional.id;
      //     this.$http
      //       .projectList({ per_page: 999999, organization_id: id })
      //       .then((res) => {
      //         if (res.status == 200) {
      //           // console.log(res.data.data);
      //           this.projList = res.data.data;
      //         }
      //       });
      //   } else {
      //     let id = this.organ.id;
      //     this.$http
      //       .projectList({ per_page: 999999, organization_id: id })
      //       .then((res) => {
      //         if (res.status == 200) {
      //           // console.log(res.data.data);
      //           this.projList = res.data.data;
      //         }
      //       });
      //   }
      // },

      // 职称列表
      // titleList() {
      //   this.career = "";
      //   if (this.careerType == 1) {
      //     this.$http
      //       .titleList({
      //         project_id: this.project,
      //       })
      //       .then((res) => {
      //         if (res.status == 200) {
      //           this.carList = res.data;
      //         }
      //       });
      //   } else {
      //     if (this.level == 0 || this.level == 1 || this.level == 2) {
      //       this.$http
      //         .titleList({
      //           area_level: this.level,
      //           project_id: this.project,
      //           organization_id: this.organ.id,
      //         })
      //         .then((res) => {
      //           if (res.status == 200) {
      //             this.carList = res.data;
      //           }
      //         });
      //     } else if (this.level == 3) {
      //       this.$http
      //         .titleList({
      //           area_level: this.level,
      //           project_id: this.project,
      //           organization_id: this.regional.id,
      //         })
      //         .then((res) => {
      //           if (res.status == 200) {
      //             this.carList = res.data;
      //           }
      //         });
      //     } else if (this.level == 4) {
      //       this.$http
      //         .titleList({
      //           area_level: this.level,
      //           project_id: this.project,
      //           organization_id: this.local,
      //         })
      //         .then((res) => {
      //           if (res.status == 200) {
      //             this.carList = res.data;
      //           }
      //         });
      //     }
      //   }
      // },

      // 申请职称
      // applyJobTitle() {
      //   if (this.beginYear != "" && this.career != "") {
      //     if (this.local != "") {
      //       this.$http
      //         .applyJobTitle({
      //           start_year: this.beginYear,
      //           end_year: this.endYear,
      //           level: this.career.level,
      //           area_level: this.level,
      //           type: this.careerType,
      //           job_title_id: this.career.id,
      //           organization_id: this.local,
      //           project_id: this.project,
      //         })
      //         .then((res) => {
      //           if (res.status == 200) {
      //             this.$message.success(res.message);
      //             this.applyJobTitleList();
      //             this.beginYear = "";
      //             this.endYear = "";
      //             this.career = {};
      //             (this.organ = {}), (this.level = "");
      //             this.careerType = "";
      //             this.career = {};
      //             this.local = "";
      //             this.project = "";
      //           }
      //         });
      //     } else if (this.regional.id) {
      //       this.$http
      //         .applyJobTitle({
      //           start_year: this.beginYear,
      //           end_year: this.endYear,
      //           level: this.career.level,
      //           area_level: this.level,
      //           type: this.careerType,
      //           job_title_id: this.career.id,
      //           organization_id: this.regional.id,
      //           project_id: this.project,
      //         })
      //         .then((res) => {
      //           if (res.status == 200) {
      //             this.$message.success(res.message);
      //             this.applyJobTitleList();
      //             this.beginYear = "";
      //             this.endYear = "";
      //             this.career = {};
      //             (this.organ = {}), (this.level = "");
      //             this.careerType = "";
      //             this.career = {};
      //             this.local = "";
      //             this.project = "";
      //           }
      //         });
      //     } else if (this.organ.id) {
      //       this.$http
      //         .applyJobTitle({
      //           start_year: this.beginYear,
      //           end_year: this.endYear,
      //           level: this.career.level,
      //           area_level: this.level,
      //           type: this.careerType,
      //           job_title_id: this.career.id,
      //           organization_id: this.organ.id,
      //           project_id: this.project,
      //         })
      //         .then((res) => {
      //           if (res.status == 200) {
      //             this.$message.success(res.message);
      //             this.applyJobTitleList();
      //             this.beginYear = "";
      //             this.endYear = "";
      //             (this.organ = {}), (this.career = {});
      //             this.level = "";
      //             this.careerType = "";
      //             this.career = {};
      //             this.local = "";
      //             this.project = "";
      //           }
      //         });
      //     } else {
      //       this.$message.warning("Organization is a must");
      //     }
      //   } else {
      //     this.$message.warning("Incomplete information");
      //   }
      // },

      // 职称申请列表
      // applyJobTitleList() {
      //   this.$http.applyJobTitleList().then((res) => {
      //     if (res.status == 200) {
      //       this.applyList = res.data.list;
      //     }
      //   });
      // },

      // 删除申请
      // titleDel(id) {
      //   this.$http.titleDel({ id }).then((res) => {
      //     if (res.status == 200) {
      //       this.applyJobTitleList();
      //     }
      //   });
      // },
    },
  };
</script>
<style lang="scss" scoped>
  @media screen and (min-width: 992px) {
    ::v-deep #Career .modal-dialog {
      max-width: 800px !important;
    }
    ::v-deep #CareerLog .modal-dialog {
      min-width: 800px !important;
    }
  }
  @media (min-width: 600px) {
    .apply_career{
      .btn_log{
        width: 120px;
        font-size: 14px;
      }
      .btn_apply{
        width: 68px;
        // font-size: 12px;
      }
    }
  }
  @media (max-width: 600px) {
    .apply_career{
      h4{
        font-size: 14px;
      }
      .btn_log{
        width: 105px;
        font-size: 12px;
      }
      .btn_apply{
        width: 55px;
        font-size: 12px;
      }
    }
    
  }
  ::v-deep #CareerLog .modal-dialog {
    // min-width: 800px !important;

    .log_container {
      height: 555px;
    }

    .el-table .warning-row {
      background: #FFF5F4;
    }

    .el-table .success-row {
      background: #F8F8F8;
    }

    .el-table .cell {
      white-space: nowrap !important;
    }

    .el-table::before {
      width: 0;
    }

    .el-table__header-wrapper {
      margin-bottom: -4px;
    }

    .el-table tr {
      background: #fafafa;
      margin-bottom: 10px;
    }

    .el-table td.el-table__cell,
    .el-table th.el-table__cell.is-leaf {
      border: none;
    }

    .el-table__body {
      border-collapse: separate;
      border-spacing: 0 10px;
      table-layout: auto !important;
    }
  }

  .career-list {
    padding: 0;

    >li {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      // 如果是最后一个li就清除掉：：before
      &:last-child {
        >span {
          &::before {
            display: none;
          }
        }
      }

      >span {
        color: #333;
        margin-right: 15px;
        position: relative;

        // 綫
        &::before {
          content: "";
          display: block;
          width: 1px;
          height: 210%;
          background-color: #f1f1f1;
          position: absolute;
          top: 50%;
          right: -18px;
        }
      }

      >p {
        margin-left: 10px;
        margin-bottom: 0;
        color: #999;
        background-color: #f4f4f4;
        padding: 9px 9px 7px 20px;
        border-radius: 5px;
        position: relative;

        // 球
        &::after {
          content: "";
          display: block;
          width: 5px;
          height: 5px;
          background: #ffba68;
          // border: 2px solid rgba(255, 186, 104, 0.2);
          box-shadow: 0px 0px 10px #ff8c00;
          border-radius: 50%;
          position: absolute;
          top: 18px;
          left: -10px;
        }
      }
    }
  }

  .empty {
    display: flex;
    justify-content: center;
    margin: 60px 0;
  }

  .apply_career {
    .no_limit_bottom {
      border-top: 1px solid #f1f1f1;
      padding-top: 30px;
      margin-top: 10px;
    }

    h6 {
      color: #333;
      margin-bottom: 10px;
      font-size: 14px;
    }

    .no_limit {
      color: #333;
      font-size: 14px;

      p {
        margin: 0;
        text-align: center;
      }

      div {
        .career {
          padding: 2px 14px;
          width: 200px;
          background: #f4f4f4;
          border-radius: 2.5px;
          margin-right: 16px;
          margin-bottom: 10px;
          font-size: 14px;

          :hover {
            cursor: pointer;
          }

          .org {
            color: #333;
            overflow: hidden; //超出隐藏
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .tit {
            color: #50b5ff;
            overflow: hidden; //超出隐藏
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          // position: relative;

          // &::before {
          //   position: absolute;
          //   left: -6px;
          //   top: 9px;
          //   content: '';
          //   width: 0px;
          //   height: 0px;
          //   border-right: 6px solid #f4f4f4;
          //   border-top: 6px solid transparent;
          //   border-bottom: 6px solid transparent;
          // }
        }
      }
    }

    .btn_log {
      height: 32px;
      background: #ffffff;
      border: 1px solid #dddddd;
      border-radius: 5px;
      line-height: 32px;
      text-align: center;
      margin-right: 10px;
      cursor: pointer;
    }

    .btn_apply {
      height: 32px;
      background: #ffffff;
      border: 1px solid #50b5ff;
      border-radius: 5px;
      line-height: 32px;
      text-align: center;
      color: #50b5ff;
      cursor: pointer;
    }
  }

  ::v-deep #Career {
    .el-date-editor {
      .el-input__inner {
        padding-left: 15px !important;
      }

      .el-input__icon {
        display: none !important;
      }
    }
  }
</style>