<template>
  <section class="Education">
    <h4 class="mb-3">Education</h4>
    <hr />
    <ul class="suggestions-lists m-0 p-0">
      <li class="d-flex mb-4 align-items-center">
        <div class="user-img img-fluid" v-if="isHideIcon">
          <button type="button" class="btn" @click="educationType('add')">
            <i class="ri-add-fill"></i>Add Education
          </button>
        </div>
      </li>
    </ul>
    <div v-if="educationlist.length > 0">
      <ul
        class="suggestions-lists m-0 p-0"
        v-for="(v, i) in educationlist"
        :key="i"
      >
        <li class="d-flex mb-4 align-items-center">
          <div class="media-support-info ml-3">
            <h6
              class="longText"
              style="font-size: 16px; font-weight: 500"
              :title="v.school"
            >
              {{ v.school }}
            </h6>
            <p
              class="mb-0 longText"
              style="color: #666"
              :title="v.department_category"
            >
              {{ v.department_category }}
            </p>
            <p class="mb-0" style="color: #333">
              {{ v.educations_start_date | filterDate }} -
              {{ v.educations_end_date | filterDate }}
            </p>
          </div>
          <a href="javascript:;" v-if="isHideIcon"
            ><span v-b-modal.editEducation @click="educationType('edit', v.id)"
              ><i class="ri-edit-line mr-2"></i>Edit</span
            ></a
          >
        </li>
      </ul>
    </div>
    <div class="empty" v-else></div>
    <!-- Education 弹窗 -->
    <b-modal
      id="editEducation"
      centered
      :title="$t('MyJCI.f50@education')"
      hide-footer
      @hidden="hideModalEducation"
    >
      <div>
        <div class="form-group">
          <label>{{ $t("MyJCI.606@school") }}</label>
          <input type="text" class="form-control" v-model="list.school" />
        </div>
        <div class="form-group">
          <label>{{ $t("MyJCI.9e8@department_category") }}</label>
          <input
            type="text"
            class="form-control"
            placeholder="Bachelor"
            v-model="list.department_category"
          />
        </div>
        <div>
          <label>{{ $t("MyJCI.0e9@enrollment_year") }}</label>
          <div class="row">
            <div class="col-6 borderNone">
              <el-date-picker
                style="width: 100%"
                v-model="list.educations_start_date"
                type="date"
                placeholder="Start Date"
                :default-value="DefaultDate"
                value-format="yyyy-MM-dd"
                :editable="false"
              >
              </el-date-picker>
            </div>
            <div class="col-6 borderNone">
              <el-date-picker
                style="width: 100%"
                v-model="list.educations_end_date"
                type="date"
                placeholder="End Date"
                :default-value="DefaultDate"
                value-format="yyyy-MM-dd"
                :editable="false"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <a
            @click="delEducation(list.id)"
            v-if="delet"
            href="javascript:;"
            style="color: #ef5959"
            ><u>{{ $t("MyJCI.649@delete") }}</u></a
          >
          <b-button
            variant="primary"
            class="float-right"
            @click="educationSubmit"
            >{{ $t("MyJCI.7d3@confirm") }}</b-button
          >
          <b-button
            class="float-right mr-2 btnOrange"
            @click="$bvModal.hide('editEducation')"
            >{{ $t("MyJCI.d99@cancel") }}</b-button
          >
        </div>
      </div>
    </b-modal>
  </section>
</template>
<script>
export default {
  name: "Education",
  components: {},
  data() {
    return {
      isAddEducation: false,
      educationlist: [],
      linkIsShow: false,
      isHideIcon: false,
      delet: false,
      picIsShow: false,
      list: {
        popupType: "",
        school: "",
        department_category: "",
        educations_start_date: "",
        educations_end_date: "",
      },
    };
  },
  filters: {
    filterDate(val) {
      return val.split("-")[0];
    },
  },
  created() {
    // this.getEducationList();
  },
  computed: {
    // 日历插件的默认日期
    DefaultDate() {
      let date = new Date();
      let y = date.getFullYear();
      return new Date(`${y}-01-01`);
    },
  },
  watch: {},
  methods: {
    // 监听弹窗关闭
    hideModalEducation(bvModalEvt, modalId) {
      if (this.isAddEducation) {
        this.list.school = "";
        this.list.department_category = "";
        this.list.educations_start_date = "";
        this.list.educations_end_date = "";
      } else {
        if (
          !this.list.school &&
          !this.list.department_category &&
          !this.list.educations_start_date &&
          !this.list.educations_end_date
        )
          return;
        this.$confirm(
          "Close the pop-up box, the form will be cleared, are you sure to continue?",
          "Tips",
          {
            distinguishCancelAndClose: true,
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(() => {})
          .catch(() => {
            this.$bvModal.show("editEducation");
          });
      }
    },
    // 上传附件
    uploadFileIMG(params) {
      var formData = new FormData();
      formData.append("file", params.file);
      this.$http.uploadImage(formData).then((res) => {
        this.list.upload_file_path = res.data[0];
        if (res.status == 200) {
          this.picIsShow = true;
        }
      });
    },
    // 判断教育经历操作类型
    educationType(type, id) {
      this.list = {};
      this.list.popupType = type;
      if (type === "add") {
        this.isAddEducation = false;
        this.picIsShow = false;
        this.delet = false;
        this.$bvModal.show("editEducation");
      } else {
        this.isAddEducation = true;
        this.$bvModal.show("editEducation");
        this.picIsShow = true;
        this.educationFirst(id);
        this.delet = true;
      }
    },
    // 教育经验列表
    getEducationList() {
      let selfId = JSON.parse(this.$getlocalStorage("userInfo")).user_id;
      let router_id = parseInt(this.$route.query.user_id);
      let id = "";
      if (router_id == selfId || !router_id) {
        id = "";
        this.isHideIcon = true;
      } else {
        id = router_id;
        this.isHideIcon = false;
      }
      this.$http.educationList({ user_id: id }).then((res) => {
        if (res.status == 200) {
          this.educationlist = res.data.data;
        }
      });
    },
    // 添加教育经历
    addEducation() {
      const data = JSON.parse(JSON.stringify(this.list));
      this.$http.addEducation(data).then((res) => {
        if (res.status == 200) {
          this.$bvModal.hide("editEducation");
          this.isAddEducation = true;
          this.getEducationList();
        }
      });
    },

    // 编辑教育经历
    editEducation() {
      const data = JSON.parse(JSON.stringify(this.list));
      this.$http
        .editEducation({
          education_id: data.id,
          school: data.school,
          department_category: data.department_category,
          educations_start_date: data.educations_start_date,
          educations_end_date: data.educations_end_date,
        })
        .then((res) => {
          // console.log(res)
          // res.data = data;
          if (res.status == 200) {
            this.getEducationList();
            this.$bvModal.hide("editEducation");
          }
        });
    },

    // 获取单条教育经历
    educationFirst(education_id) {
      this.$http.educationFirst({ education_id }).then((res) => {
        if (res.status == 200) {
          this.list = res.data;
          // console.log(this.list)
        }
      });
    },
    // 提交
    educationSubmit() {
      if (
        this.list.id != "" &&
        this.list.school != "" &&
        this.list.department_category != "" &&
        this.list.educations_start_date != "" &&
        this.list.educations_end_date != ""
      ) {
        this.list.popupType == "add"
          ? this.addEducation()
          : this.editEducation();
        this.getEducationList();
      }
    },
    // 删除教育经历
    delEducation(id) {
      this.$http
        .delEducation({
          education_id: id,
        })
        .then((res) => {
          if (res.status == 200) {
            this.$bvModal.hide("editEducation");
            this.getEducationList();
          }
        });
    },
  },
};
</script>
<style lang='scss' scoped>
@media screen and (min-width: 992px) {
  ::v-deep #editEducation .modal-dialog {
    max-width: 800px !important;
  }
}
@media (min-width: 600px) {
    .longText{
      max-width: 500px;
    }
    
  }
  @media (max-width: 600px) {
    .longText{
      max-width: 160px;
    }
  }
::v-deep #editEducation .modal-dialog {
  margin: 5rem auto;
}
textarea {
  height: 50px !important;
  line-height: 35px !important;
}
#editEducation {
  .modalMedia,
  .desc {
    color: #3f414d;
    line-height: 26px;
  }
}
.Education {
  .opacity {
    opacity: 0;
  }
}
.longText {
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.empty {
  display: flex;
  justify-content: center;
  margin: 60px 0;
}
::v-deep .borderNone {
  .form-control {
    .form-control {
      border: 0;
      line-height: 33px;
    }
  }
}
.el-date-editor::v-deep {
  .el-input__inner {
    border-radius: 10px;
    border: 1px solid #dcdfe6;
    height: 45px;
  }
}
</style>
