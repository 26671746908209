<template>
  <div class="row profile_project">
    <div class="col-md-3 col-3">
      <tab-nav :pills="true" id="tab" class="nav nav-pills basic-info-items list-inline d-block p-0 m-0">
        <tab-nav-items :active="true" id="pills-family-info" href="#family-info" ariaControls="pills-family-info"
          role="tab" :ariaSelected="true" title="Published" @click.native="getPushed()" />
        <tab-nav-items :active="false" id="pills-contact-info" href="#career" ariaControls="pills-contact-info"
          role="tab" :ariaSelected="true" title="Draft" @click.native="getDraft()" />
      </tab-nav>
    </div>
    <div class="col-md-9 col-9">
      <div class="tab-content">
        <tab-content-item :active="true" id="family-info" aria-labelled-by="pills-family-info">
          <!-- <div v-if="loading">
            <el-skeleton animated>
              <template slot="template">
                <iq-card body-class="p-0" style="border-radius: 5px;overflow: hidden;">
                  <el-skeleton-item variant="image" style="width: 100%; height: 160px;" />
                  <div style="padding: 22px 10px 20px;" class="d-flex flex-column">
                    <el-skeleton-item variant="text" style="width: 60%;margin-bottom: 20px;" />
                    <el-skeleton-item variant="text" style="width: 40%;margin-bottom: 20px;" />
                    <el-skeleton-item variant="text" style="width: 60%" />
                  </div>
                </iq-card>
              </template>
            </el-skeleton>
          </div> -->
          <div  class="cardProject pb-3 mt-3">
            <section class="Offline" >
              <b-row>
                <b-col v-for="(item, index) in projectList" :key="index" md="6" lg="6" ms="12" class="cursor-pointer"
                  @click="$router.push(`/projects-detail?id=${item.id}&is_user=1`)">
                  <Prerecording :cardInfo="item" :is_edit="true" @openDialog='openDialog' />
                </b-col>
              </b-row>
            </section>
            <section v-if="!projectList.length" class="d-flex justify-content-center" style="margin-top: 60px">
              <!-- <img style="width:250px;" src="@/assets/images/group/empty1.png" alt="" /> -->
            </section>
          </div>
        </tab-content-item>
      </div>
    </div>

    <!-- applyRequest -->
    <CreateProjectDialog ref="child" titleText="Edit Project" />
  </div>
</template>
<script>

  import Prerecording from '../../ProjectGallery/components/Prerecording'
  import CreateProjectDialog from '../../ProjectGallery/components/CreateProjectDialog'
  export default {
    name: 'Projects',
    components: {
      Prerecording,
      CreateProjectDialog
    },
    props: ['isHideIcon'],
    data() {
      return {
        active: 'family-info',
        projectList: [],
        page: 1,
        size: 12,
        total: 0,
      }
    },
    mounted() {
      // this.getProjectList()
    },
    methods: {
      handleCurrentChange(page) {
        this.page = page
        this.getProjectList()
      },

      openDialog(id) {
        this.$refs.child.openDialog(id)
      },

      getPushed() {
        this.type = 2
        this.page = 1
        this.getProjectList()
      },

      getDraft() {
        this.type = 3
        this.page = 1
        this.getProjectList()
      },

      // 获取项目列表
      async getProjectList() {
        this.loading = true;
        let res = await this.$http.getProjectList({
          type: this.type || 2,
          per_page: this.size,
          page: this.page
        })
        if (res.status == 200) {
          this.total = res.data.total
          this.projectList = res.data.data || []
        }
        setTimeout(() => {
          this.loading = false
        }, 0);
      },

    }
  }
</script>
<style lang="scss" scoped>
  .profile_project {
    ::v-deep .el-dialog__body {
      padding: 0;
    }
  }
  @media (min-width: 600px) {
    .cardProject{
      padding-left: 1rem;
    }
  }
  @media (max-width: 600px) {
  }
</style>