<template>
  <section class="Hobby">
    <div class="d-flex align-items-center justify-content-between">
      <h4>Hashtags</h4>
      <div class="edit-relation position-relative">
        <button
          type="button"
          class="w-100 btn btn-primary h-100 position-absolute left-0 top-0 opacity"
          v-b-modal.EditHobby
          @click="(hobbies = ''),
              (page = 1),
              (hobList = []),
              (hashTag=[]),
              (disabled = false),
              hobbyList()"
        ></button>
        <a href="javascript:;" v-if="isHideIcon"
          ><i class="ri-edit-line mr-2"></i>{{$t('MyJCI.3b1@edit')}}</a
        >
      </div>
    </div>
    <hr />
    <!-- <ul class="suggestions-lists m-0 p-0 d-flex">
      <li
        class="d-flex mb-4 align-items-center position-relative"
        v-if="isHideIcon"
      >
        <button
          type="button"
          class="w-100 btn h-100 position-absolute left-0 top-0 opacity"
          v-b-modal.EditHobby
          @click="
            (hobbies = ''),
              (page = 1),
              (hobList = []),
              (disabled = false),
              hobbyList()
          "
        ></button>
        <div class="user-img img-fluid">
          <i class="ri-add-fill"></i>
        </div>
        <div class="media-support-info ml-3">
          <h6>Add Hashtags</h6>
        </div>
      </li>
    </ul> -->
    <div class="row" v-if="hobbiesList.length>0">
      <span
        class="mb-0 mr-1 mt-1 yellow-text-bg"
        v-for="(v, i) of hobbiesList"
        :key="i"
        >
        <span :title="v.hobbies_name">{{ v.hobbies_name }}</span></span
      >
    </div>
    <div class="empty" v-else>
      
    </div>
    <!-- Edit Hobby 弹窗 -->
    <!-- <b-modal id="EditHobby" centered :title="$t('MyJCI.c02@edit_hobby')" hide-footer>
      <div class="searchBox" @keyup.enter="pushHobbies">
        <input
          type="text"
          class="form-control"
          v-model="hobbies"
          :placeholder="$t('MyJCI.043@hobby(as_=>_sing,dan')"
        />
        <img
          src="@/assets/images/course/search.png"
          @click="pushHobbies"
          alt=""
        />
        <button @click="pushHobbies">{{$t('MyJCI.d69@add')}}</button>
      </div>
      <h5 class="mt-3">{{$t('MyJCI.7c3@your_hobby')}} :</h5>
      <ul class="p-0 d-flex flex-warp hobbyBtn">
        <li
          class="d-flex justify-content-center align-items-center p-4"
          v-for="(v, i) of hobList"
          :key="i"
        >
          {{ v.hobbies_name }}
          <img
            @click="delHobbies(i)"
            src="@/assets/images/public/close.png"
            alt=""
          />
        </li>
      </ul>
      <hr />
      <b-button
        variant="primary"
        class="mt-3 float-right mb-2"
        @click="addHobbies(hobList)"
        >{{$t('MyJCI.7d3@confirm')}}</b-button
      >
      <b-button
        class="mt-3 float-right btnOrange mr-2 mb-2"
        @click="
          $bvModal.hide('EditHobby');
          userHobbies();
        "
        >{{$t('MyJCI.d99@cancel')}}</b-button
      >
    </b-modal> -->

    <!-- Edit Hashtag 弹窗 -->
    <b-modal id="EditHobby" centered title="Edit Hashtag" hide-footer>
      <span>Hashtag</span>
      <div
        class="searchBox d-flex justify-content-between align-items-center mt-2"
      >
        <el-input type="text" @input="hobbyLimit" maxlength="100" v-model="hobbies" style="width: 660px"></el-input>
        <!-- <div @click="pushHobbies" class="btn_apply">Apply</div> -->
        <div @click="pushHobbies" class="btn_apply">Add</div>
      </div>

      <!-- Hashtag list -->
      <div
        class="hashtag_list"
        style="margin: 30px 0 45px"
        v-if="hobList.length !='0'"
      >
        <div class="hashtag_title px-3">
          <el-row>
            <el-col :span="9">Hashtag</el-col>
            <el-col :span="6">Application status</el-col>
            <el-col :span="6">Application time</el-col>
            <el-col :span="2"> </el-col>
          </el-row>
        </div>
        <div
          class="hashtag_content"
          v-infinite-scroll="infiniteData"
          infinite-scroll-disabled="disabled"
        >
          <div
            class="hashtag_item px-3 mt-2"
            v-for="(item, i) in hobList"
            :key="i"
          >
            <el-row class="d-flex align-items-center py-1">
              <el-col :span="9" class="longText" :title="item.hobbies_name">{{ item.hobbies_name }}</el-col>
              <el-col :span="6" class="longText"
                >{{ item.status_text }}
                <span v-if="item.reason_rejection" style="color: #999999"
                  >({{ item.reason_rejection }})</span
                >
              </el-col>
              <el-col :span="6" class="longText">{{ item.created_at }}</el-col>
              <el-col :span="3" class="longText">
                <div
                  v-if="item.status_text"
                  class="d-flex justify-content-end cursor-pointer"
                  @click="delHobbies(i, item.id)"
                >
                  <i class="ri-delete-bin-line" style="font-size: 18px"></i>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>

      <hr />
      <b-button
        variant="primary"
        class="mt-3 float-right mb-2"
        style="width: 78px"
        @click="addHobbies(hobList)"
      >
        {{ $t("Headbar.d1a@save") }}
      </b-button>
      <b-button
        class="mt-3 float-right btnOrange mr-2 mb-2"
        @click="
          $bvModal.hide('EditHobby');
          userHobbies();
        "
        >{{ $t("MyJCI.d99@cancel") }}</b-button
      >
    </b-modal>
  </section>
</template>
<script>
import { InfiniteScroll } from "element-ui";
import moment from "moment";
export default {
  name: "Hobby",
  components: {},
  data() {
    return {
      hobbiesList: [],
      hobList: [], //暂存区
      hashTag:[],
      hobbies: "",
      isHideIcon: false,
      list: {},
      page: 1,
      disabled: false,
      limit: 10,
    };
  },
  computed: {},
  watch: {},
  directives: {
    "infinite-scroll": InfiniteScroll,
  },
  created() {},
  methods: {
    hobbyLimit(){
      // console.log(this.hobbies);
      // this.hobbies = this.hobbies.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]“”【】（）《》‘’。，`]/g,"")
      this.hobbies = this.hobbies.replace(/[^\w\u4E00-\u9FA5_]/g,"")
    },
    infiniteData() {
      this.page++;
      // this.userHobbies("edit");
    },

    hobbyList() {
      this.$http.userHobbies({status:""}).then((res) => {
        if (res.status == 200) {
            this.hobList = res.data;
        }
      });
    },
    // 获取爱好列表
    userHobbies(type) {
      // if (!type) {
      //   this.page = "";
      //   this.limit = "";
      // } else {
      //   this.limit = 10;
      // }
      let selfId = JSON.parse(this.$getlocalStorage("userInfo")).user_id;
      let router_id = parseInt(this.$route.query.user_id);
      let id = "";
      if (router_id == selfId || !router_id) {
        id = "";
        this.isHideIcon = true;
      } else {
        id = router_id;
        this.isHideIcon = false;
      }
      let data = { user_id: id, status:1  };
      this.$http.userHobbies(data).then((res) => {
        if (res.status == 200) {
          if (type) {
            if (!res.data.length) {
              this.disabled = true;
              return;
            }
            console.log(res.data);
            // this.hobList = [...this.hobList, ...res.data];
            this.hobList = res.data;
          } else {
            this.hobbiesList = res.data;
          }
        }
      });
    },

    // 在弹框中添加爱好
    pushHobbies() {
      if (this.hobbies !== "") {
        const list = {
          hobbies_name: this.hobbies,
          status_text: "Pending",
          created_at: moment(new Date()).format("YYYY.MM.DD HH:mm:ss"),
        };
        this.hobList.unshift(list);
        this.hashTag.unshift(list);
        this.hobbies = "";
      }
    },


    // 新增爱好
    addHobbies() {
      let data = this.hashTag
        .filter((v) => !v.id)
        .map((v) => {
          return { hobbies_name: v.hobbies_name };
        });
      if (!data.length) return this.$bvModal.hide("EditHobby");
      this.$http.addHobbies({ hobbies_name: data }).then((res) => {
        if (res.status == 200) {
          this.userHobbies();
          this.$bvModal.hide("EditHobby");
        }
      });
    },

    // 删除爱好
    delHobbies(i, id) {
      if (id) {
        this.$http.delHobbies({ user_hobbies_id: id }).then((res) => {
          if (res.status == 200) {
            this.hobList.splice(i, 1);
            this.userHobbies();
            this.$message.success("Delete successfully!");
          }
        });
      } else {
        this.hobList.splice(i, 1);
        this.hashTag.splice(i, 1);
      }
    },
  },
};
</script>
<style src='' lang='scss' scoped>
@media screen and (min-width: 992px) {
  ::v-deep #EditHobby .modal-dialog {
    max-width: 800px !important;
  }
}
.longText {
  max-width: 300px;
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.empty{
  display: flex;
  justify-content: center;
  margin: 60px 0;
}
.searchBox {
  position: relative;

  /* button {
      position: absolute;
      right: 0px;
      height: 45px;
      width: 100px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      background-color: #fff;
      border: 1px solid #ced4da;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    } */

  .btn_apply {
    width: 94px;
    height: 35px;
    margin-left: 5px;
    border: 1px solid #50b5ff;
    border-radius: 5px;
    text-align: center;
    color: #50b5ff;
    line-height: 35px;
    cursor: pointer;
  }
}

.btns {
  margin: 20px 0 50px 0;

  li {
    height: 45px;
    background: #f5fbff;
    border-radius: 23px;
    color: #50b5ff;
  }
}

.hobbyBtn {
  flex-wrap: wrap;

  li {
    background: #fffcf3;
    color: #ffba68;
    position: relative;
    margin: 5px;

    img {
      position: absolute;
      top: -10px;
      right: -10px;
      cursor: pointer;
    }
  }
}

.Hobby {
  .opacity {
    opacity: 0;
  }

  .yellow-text-bg {
    padding: 12px 8px;
    background-color: #fffcf3;
    color: #ffba68;
    max-width: 600px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }
}

.hashtag_list {
  width: 100%;
  .hashtag_title {
    color: #333333;
  }

  .hashtag_content {
    max-height: 300px;
    overflow: auto;
  }

  .hashtag_item {
    background: #f8f8f8;
    margin-top: 5px;
  }
}
</style>
